<div class="dplayer-mask"></div>
<div class="dplayer-video-wrap">
    {{ include './video.art' video }}
    {{ if options.logo }}
    <div class="dplayer-logo">
        <img src="{{ options.logo }}">
    </div>
    {{ /if }}
    <div class="dplayer-danmaku"{{ if options.danmaku && options.danmaku.bottom }} style="margin-bottom:{{ options.danmaku.bottm }}"{{ /if }}>
        <div class="dplayer-danmaku-item dplayer-danmaku-item--demo"></div>
    </div>
    <div class="dplayer-subtitles-container"></div>
    <div class="dplayer-bezel">
        <span class="dplayer-bezel-icon"></span>
        {{ if options.danmaku }}
        <span class="dplayer-danloading">{{ translate('danmaku-loading') }}</span>
        {{ /if }}
        <span class="diplayer-loading-icon">{{@ icons.loading }}</span>
    </div>
</div>
<div class="dplayer-controller-mask"></div>
<div class="dplayer-controller">
    <div class="dplayer-icons dplayer-comment-box">
        <button class="dplayer-icon dplayer-comment-setting-icon" {{@ balloon('setting','up')}}>
            <span class="dplayer-icon-content">{{@ icons.pallette }}</span>
        </button>
        <div class="dplayer-comment-setting-box">
            <div class="dplayer-comment-setting-color">
                <div class="dplayer-comment-setting-title">{{ translate('set-danmaku-color') }}</div>
                <label>
                    <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#fff" checked>
                    <span style="background: #fff;"></span>
                </label>
                <label>
                    <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#e54256">
                    <span style="background: #e54256"></span>
                </label>
                <label>
                    <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#ffe133">
                    <span style="background: #ffe133"></span>
                </label>
                <label>
                    <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#64DD17">
                    <span style="background: #64DD17"></span>
                </label>
                <label>
                    <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#39ccff">
                    <span style="background: #39ccff"></span>
                </label>
                <label>
                    <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#D500F9">
                    <span style="background: #D500F9"></span>
                </label>
            </div>
            <div class="dplayer-comment-setting-type">
                <div class="dplayer-comment-setting-title">{{ translate('set-danmaku-type') }}</div>
                <label>
                    <input type="radio" name="dplayer-danmaku-type-{{ index }}" value="1">
                    <span>{{ translate('top') }}</span>
                </label>
                <label>
                    <input type="radio" name="dplayer-danmaku-type-{{ index }}" value="0" checked>
                    <span>{{ translate('rolling') }}</span>
                </label>
                <label>
                    <input type="radio" name="dplayer-danmaku-type-{{ index }}" value="2">
                    <span>{{ translate('bottom') }}</span>
                </label>
            </div>
        </div>
        <input class="dplayer-comment-input" type="text" placeholder="{{ translate('input-danmaku-enter') }}" maxlength="30">
        <button class="dplayer-icon dplayer-send-icon" {{@ balloon('send','up')}}>
            <span class="dplayer-icon-content">{{@ icons.send }}</span>
        </button>
    </div>
    <div class="dplayer-icons dplayer-icons-left">
        <button class="dplayer-icon dplayer-play-icon">
            <span class="dplayer-icon-content">{{@ icons.play }}</span>
        </button>
        <div class="dplayer-volume">
            <button class="dplayer-icon dplayer-volume-icon">
                <span class="dplayer-icon-content">{{@ icons.volumeDown }}</span>
            </button>
            <div class="dplayer-volume-bar-wrap" data-balloon-pos="up">
                <div class="dplayer-volume-bar">
                    <div class="dplayer-volume-bar-inner" style="background: var(--dplayer-theme-color);">
                        <span class="dplayer-thumb" style="background: var(--dplayer-theme-color);"></span>
                    </div>
                </div>
            </div>
        </div>
        <span class="dplayer-time">
            <span class="dplayer-ptime">0:00</span> /
            <span class="dplayer-dtime">0:00</span>
        </span>
        {{ if options.live }}
        <span class="dplayer-live-badge"><span class="dplayer-live-dot" style="background: var(--dplayer-theme-color);"></span>{{ translate('live') }}</span>
        {{ /if }}
    </div>
    <div class="dplayer-icons dplayer-icons-right">
        {{ if options.video.quality }}
        <div class="dplayer-quality">
            <button class="dplayer-icon dplayer-quality-icon">{{ options.video.quality[options.video.defaultQuality].name }}</button>
            <div class="dplayer-quality-mask">
                <div class="dplayer-quality-list">
                {{ each options.video.quality }}
                    <div class="dplayer-quality-item" data-index="{{ $index }}">{{ $value.name }}</div>
                {{ /each }}
                </div>
            </div>
        </div>
        {{ /if }}
        {{ if options.screenshot }}
        <div class="dplayer-icon dplayer-camera-icon" {{@ balloon('screenshot','up')}}>
            <span class="dplayer-icon-content">{{@ icons.camera }}</span>
        </div>
        {{ /if }}
        {{ if options.airplay }}
        <div class="dplayer-icon dplayer-airplay-icon" {{@ balloon('airplay','up')}}>
            <span class="dplayer-icon-content">{{@ icons.airplay }}</span>
        </div>
        {{ /if }}
        {{ if options.chromecast }}
        <div class="dplayer-icon dplayer-chromecast-icon" {{@ balloon('chromecast','up')}}>
            <span class="dplayer-icon-content">{{@ icons.chromecast }}</span>
        </div>
        {{ /if }}
        <div class="dplayer-comment">
            <button class="dplayer-icon dplayer-comment-icon" {{@ balloon('send-danmaku','up')}}'>
                <span class="dplayer-icon-content">{{@ icons.comment }}</span>
            </button>
        </div>
        {{ if options.subtitle }}
        {{ if (options.subtitle.url.length <= 2) }}
        <div class="dplayer-subtitles-btn">
            <button class="dplayer-icon dplayer-subtitles-icon" {{@ balloon('hide-subs','up')}}>
                <span class="dplayer-icon-content">{{@ icons.subtitle }}</span>
            </button>
        </div>
        {{ else }}
        <div class="dplayer-subtitles-multiple dplayer-subtitles-btn">
            <button class="dplayer-icon dplayer-subtitles-icon" {{@ balloon('subtitle','up')}}>
                <span class="dplayer-icon-content">{{@ icons.subtitle }}</span>
            </button>
            <div class="dplayer-subtitles-box">
                <div class="dplayer-subtitles-panel">
                    {{ each options.subtitle.url }}
                        <div class="dplayer-subtitles-item" data-subtitle="{{ $value.subtitle }}">
                            <!-- TODO(#77): better langauge support, maybe language Flags, (lang) is kinda strange, especially when chinese(simplified) is also in the languages-->
                            
                            <!-- if lang, show translate(lang). if lang and name, show name + (translate(lang)). if name, show name. off option uses lang for translation. -->
                            <span class="dplayer-label">{{ $value.lang ? $value.name ?  $value.name+' ('+translate($value.lang)+')' : translate($value.lang) : $value.name }}</span>
                        </div>
                    {{ /each }}
                </div>
            </div>
        </div>
        {{ /if }}
        {{ /if }}
        <div class="dplayer-setting">
            <button class="dplayer-icon dplayer-setting-icon" {{@ balloon('setting','up')}}>
                <span class="dplayer-icon-content">{{@ icons.setting }}</span>
            </button>
            <div class="dplayer-setting-box">
                <div class="dplayer-setting-origin-panel">
                    <div class="dplayer-setting-item dplayer-setting-speed">
                        <span class="dplayer-label">{{ translate('speed-raw') }}</span>
                        <div class="dplayer-toggle">{{@ icons.right }}</div>
                    </div>
                    <div class="dplayer-setting-item dplayer-setting-loop">
                        <span class="dplayer-label">{{ translate('loop') }}</span>
                        <div class="dplayer-toggle">
                            <input class="dplayer-toggle-setting-input" type="checkbox" name="dplayer-toggle">
                            <label for="dplayer-toggle"></label>
                        </div>
                    </div>
                    <div class="dplayer-setting-item dplayer-setting-showdan">
                        <span class="dplayer-label">{{ translate('show-danmaku') }}</span>
                        <div class="dplayer-toggle">
                            <input class="dplayer-showdan-setting-input" type="checkbox" name="dplayer-toggle-dan">
                            <label for="dplayer-toggle-dan"></label>
                        </div>
                    </div>
                    <div class="dplayer-setting-item dplayer-setting-danunlimit">
                        <span class="dplayer-label">{{ translate('unlimited-danmaku') }}</span>
                        <div class="dplayer-toggle">
                            <input class="dplayer-danunlimit-setting-input" type="checkbox" name="dplayer-toggle-danunlimit">
                            <label for="dplayer-toggle-danunlimit"></label>
                        </div>
                    </div>
                    <div class="dplayer-setting-item dplayer-setting-danmaku">
                        <span class="dplayer-label">{{ translate('opacity-danmaku') }}</span>
                        <div class="dplayer-danmaku-bar-wrap">
                            <div class="dplayer-danmaku-bar">
                                <div class="dplayer-danmaku-bar-inner">
                                    <span class="dplayer-thumb"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dplayer-setting-speed-panel">
                    {{ each options.playbackSpeed }}
                        <div class="dplayer-setting-speed-item" data-speed="{{ $value }}">
                            <span class="dplayer-label">{{ $value === 1 ? translate('normal') : $value }}</span>
                        </div>
                    {{ /each }}
                </div>
            </div>
        </div>
        <div class="dplayer-full">
            <button class="dplayer-icon dplayer-full-in-icon" {{@ balloon('web-fullscreen','up')}}>
                <span class="dplayer-icon-content">{{@ icons.fullWeb }}</span>
            </button>
            <button class="dplayer-icon dplayer-full-icon" {{@ balloon('fullscreen','up')}}>
                <span class="dplayer-icon-content">{{@ icons.full }}</span>
            </button>
        </div>
    </div>
    <div class="dplayer-bar-wrap">
        <div class="dplayer-info-div hidden">
            <div class="dplayer-bar-time">00:00</div>
            <div class="dplayer-thumb-text hidden"></div>
            <div class="dplayer-bar-preview hidden"></div>
        </div>
        <div class="dplayer-bar">
            <div class="dplayer-loaded" style="width: 0;"></div>
            <div class="dplayer-played" style="width: 0; background: var(--dplayer-theme-color);">
                <span class="dplayer-thumb" style="background: {var(--dplayer-theme-color);"></span>
            </div>
        </div>
    </div>
</div>
<div class="dplayer-info-panel dplayer-info-panel-hide">
    <div class="dplayer-info-panel-close">[x]</div>
    <div class="dplayer-info-panel-item dplayer-info-panel-item-version">
        <span class="dplayer-info-panel-item-title">Player version</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    <div class="dplayer-info-panel-item dplayer-info-panel-item-fps">
        <span class="dplayer-info-panel-item-title">Player FPS</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    <div class="dplayer-info-panel-item dplayer-info-panel-item-type">
        <span class="dplayer-info-panel-item-title">Video type</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    <div class="dplayer-info-panel-item dplayer-info-panel-item-url">
        <span class="dplayer-info-panel-item-title">Video url</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    <div class="dplayer-info-panel-item dplayer-info-panel-item-resolution">
        <span class="dplayer-info-panel-item-title">Video resolution</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    <div class="dplayer-info-panel-item dplayer-info-panel-item-duration">
        <span class="dplayer-info-panel-item-title">Video duration</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    {{ if options.danmaku }}
    <div class="dplayer-info-panel-item dplayer-info-panel-item-danmaku-id">
        <span class="dplayer-info-panel-item-title">Danmaku id</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    <div class="dplayer-info-panel-item dplayer-info-panel-item-danmaku-api">
        <span class="dplayer-info-panel-item-title">Danmaku api</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    <div class="dplayer-info-panel-item dplayer-info-panel-item-danmaku-amount">
        <span class="dplayer-info-panel-item-title">Danmaku amount</span>
        <span class="dplayer-info-panel-item-data"></span>
    </div>
    {{ /if }}
</div>
<div class="dplayer-hotkey-panel dplayer-hotkey-panel-hide">
    <div class="dplayer-hotkey-panel-close">[x]</div>
    <div class="dplayer-hotkey-panel-item dplayer-hotkey-panel-item-keyboard simple-keyboard">
    </div>
</div>
<div class="dplayer-menu">
    {{ each options.contextmenu }}
        <div class="dplayer-menu-item">
            <a{{ if $value.link }} target="_blank"{{ /if }} href="{{ $value.link || 'javascript:void(0);' }}">{{ if $value.key }} {{ translate($value.key) }}{{ /if }}{{ if $value.text }} {{$value.text}}{{ /if }}</a>
        </div>
    {{ /each }}
</div>
<div class="dplayer-notice-list"></div>

<div class="dplayer-skip-window" style="display:none">
    <div class="container-window">
        <div class="title">TITEL</div>
        <button class="skip">überspringen</button>
    </div>
    <div class="progress"></div>
    </canvas>
</div>

<button class="dplayer-mobile-play">
    {{@ icons.play }}
</button>
