function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
    throw new TypeError('Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.');
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o)
        return;
    if (typeof o === 'string')
        return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === 'Object' && o.constructor)
        n = o.constructor.name;
    if (n === 'Map' || n === 'Set')
        return Array.from(o);
    if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
        return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== 'undefined' && iter[Symbol.iterator] != null || iter['@@iterator'] != null)
        return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr))
        return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length)
        len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
    }
    return arr2;
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
var Events = function () {
    function Events() {
        _classCallCheck(this, Events);
        this.events = {};
        this.currentUUID = 0;
        this.videoEvents = [
            'abort',
            'canplay',
            'canplaythrough',
            'durationchange',
            'emptied',
            'ended',
            'error',
            'loadeddata',
            'loadedmetadata',
            'loadstart',
            'mozaudioavailable',
            'pause',
            'play',
            'playing',
            'progress',
            'ratechange',
            'seeked',
            'seeking',
            'stalled',
            'suspend',
            'timeupdate',
            'volumechange',
            'waiting'
        ];
        this.playerEvents = [
            'screenshot',
            'thumbnails_show',
            'thumbnails_hide',
            'danmaku_show',
            'danmaku_hide',
            'danmaku_clear',
            'danmaku_loaded',
            'danmaku_send',
            'danmaku_opacity',
            'contextmenu_show',
            'contextmenu_hide',
            'notice_show',
            'notice_hide',
            'quality_start',
            'quality_end',
            'destroy',
            'resize',
            'fullscreen',
            'fullscreen_cancel',
            'webfullscreen',
            'webfullscreen_cancel',
            'subtitle_show',
            'subtitle_hide',
            'subtitle_change',
            'subtitle_switch',
            'chapter',
            'highlight_change',
            'cancelskip',
            'ranges_change'
        ];
    }
    _createClass(Events, [
        {
            key: 'on',
            value: function on(name, callback) {
                var _this = this;
                var once = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
                var delayed = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
                if (name === 'all' || name === '*') {
                    name = [].concat(_toConsumableArray(this.playerEvents), _toConsumableArray(this.videoEvents));
                }
                if (Array.isArray(name)) {
                    return name.map(function (a) {
                        return _this.on(a, callback, once, delayed);
                    });
                } else {
                    if (this.type(name) && typeof callback === 'function') {
                        if (!this.events[name]) {
                            this.events[name] = [];
                        }
                        var UUID = this.currentUUID++;
                        this.events[name].push({
                            callback: callback,
                            once: once,
                            delayed: delayed,
                            UUID: UUID
                        });
                        return UUID;
                    }
                }
                return null;
            }
        },
        {
            key: 'once',
            value: function once(name, callback) {
                var delayed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
                return this.on(name, callback, true, delayed);
            }
        },
        {
            key: 'off',
            value: function off(name) {
                if (this.type(name)) {
                    if (this.events[name]) {
                        this.events[name] = null;
                    }
                }
            }
        },
        {
            key: 'removeByUUID',
            value: function removeByUUID(uuid) {
                var _this2 = this;
                if (Array.isArray(uuid)) {
                    return uuid.every(function (a) {
                        return _this2.removeByUUID(a);
                    });
                } else {
                    if (typeof uuid === 'number') {
                        var allEvents = this.videoEvents.concat(this.playerEvents);
                        for (var i = 0; i < allEvents.length; i++) {
                            var array = this.events[allEvents[i]];
                            if (typeof array === 'undefined' || array.length === 0) {
                                continue;
                            }
                            for (var j = 0; j < array.length; j++) {
                                var UUID = array[j].UUID;
                                if (UUID === uuid) {
                                    this.events[allEvents[i]].splice(j, 1);
                                    return true;
                                }
                            }
                        }
                    }
                }
                return false;
            }
        },
        {
            key: 'trigger',
            value: function trigger(name, info) {
                if (this.events[name] && this.events[name].length > 0) {
                    for (var i = 0; i < this.events[name].length; i++) {
                        if (!this.events[name][i].delayed) {
                            this.events[name][i].callback(info, {
                                UUID: this.events[name][i].UUID,
                                event: name
                            });
                        }
                    }
                    for (var _i = this.events[name].length - 1; _i > 0; _i--) {
                        if (this.events[name][_i].delayed) {
                            this.events[name][_i].delayed = false;
                        } else if (this.events[name][_i].once) {
                            this.events[name].splice(_i, 1);
                        }
                    }
                }
            }
        },
        {
            key: 'type',
            value: function type(name) {
                if (this.playerEvents.indexOf(name) !== -1) {
                    return 'player';
                } else if (this.videoEvents.indexOf(name) !== -1) {
                    return 'video';
                }
                console.error('Unknown event name: '.concat(name));
                return null;
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                var _this3 = this;
                Object.keys(this.events).forEach(function (key) {
                    _this3.off(key);
                });
            }
        }
    ]);
    return Events;
}();
export default Events;