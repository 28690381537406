function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
    throw new TypeError('Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.');
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o)
        return;
    if (typeof o === 'string')
        return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === 'Object' && o.constructor)
        n = o.constructor.name;
    if (n === 'Map' || n === 'Set')
        return Array.from(o);
    if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
        return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== 'undefined' && iter[Symbol.iterator] != null || iter['@@iterator'] != null)
        return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr))
        return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length)
        len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
    }
    return arr2;
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
import Keyboard from 'simple-keyboard';
var HotkeyPanel = function () {
    function HotkeyPanel(player) {
        var _this = this;
        _classCallCheck(this, HotkeyPanel);
        this.container = player.template.hotkeyPanel;
        this.template = player.template;
        this.video = player.video;
        this.player = player;
        this.layout = this.getLayout();
        this.hotkeys = this.player.hotkey.keys();
        this.template.hotkeyPanelClose.addEventListener('click', function () {
            _this.hide();
        });
        var all = Array.from(document.getElementsByClassName('simple-keyboard'));
        var keyboard = all[all.length - 1];
        var name = 'keyboard-hotkey-visualizer-'.concat(all.length);
        keyboard.classList.add(name);
        keyboard.classList.remove('simple-keyboard');
        var btThemes = [{
                'class': 'constant-width',
                buttons: '{insert} {home} {pageup} {delete} {end} {pagedown} {whitespace} {arrowup} {arrowleft} {arrowdown} {arrowright}'
            }];
        if (this.hotkeys.enabled.length > 0) {
            btThemes.push({
                'class': 'shortcuts-enabled',
                buttons: this.hotkeys.enabled.map(function (obj) {
                    return obj.key;
                }).join(' ')
            });
        }
        if (this.hotkeys.disabled.length > 0) {
            btThemes.push({
                'class': 'shortcuts-disabled',
                buttons: this.hotkeys.disabled.map(function (obj) {
                    return obj.key;
                }).join(' ')
            });
        }
        this.keyboard = new Keyboard('.'.concat(name), {
            layout: this.layout,
            display: {
                '{altgreek}': 'Alt Gr',
                '{control}': 'Ctrl',
                '{OS}': 'OS',
                '{whitespace}': '\0',
                '{border}': '\0',
                '{home': '',
                '{function}': 'Fn',
                '{enter}': '\u23CE',
                '{enter2}': 'enter',
                '{space}': '\u2423',
                '{bksp}': '\u2190',
                '{tab}': '\u21B9',
                '{lock}': '\u21EA',
                '{shiftleft}': '\u21E7',
                '{shift}': '\u21E7'
            },
            theme: 'simple-keyboard hg-theme-default hg-layout-default',
            mergeDisplay: true,
            buttonAttributes: [].concat(_toConsumableArray(this.hotkeys.all.map(function (obj) {
                return {
                    attribute: 'title',
                    value: _this.player.translate(obj.tooltip),
                    buttons: obj.key
                };
            })), _toConsumableArray(this.hotkeys.all.map(function (obj) {
                return {
                    attribute: 'data-keyCode',
                    value: ''.concat(obj.keyCode),
                    buttons: obj.key
                };
            }))),
            buttonTheme: [].concat(btThemes),
            useButtonTag: false,
            inputPattern: new RegExp('['.concat(this.hotkeys.enabled.map(function (obj) {
                return obj.key;
            }).join(''), ']')),
            baseClass: name
        });
    }
    _createClass(HotkeyPanel, [
        {
            key: 'getLayout',
            value: function getLayout() {
                var layout = {
                    'default': [
                        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp} {border} {insert} {home} {pageup}',
                        '{tab} q w e r t y u i o p [ ] {enter} {border} {delete} {end} {pagedown}',
                        '{lock} a s d f g h j k l ; \' {enter2} {border} {whitespace} {whitespace} {whitespace}',
                        '{shiftleft} z x c v b n m , . / {shift} {border} {whitespace} {arrowup} {whitespace}',
                        '{control} {OS} {alt} {space} {altgreek} {function} {control} {border} {arrowleft} {arrowdown} {arrowright}'
                    ]
                };
                return layout;
            }
        },
        {
            key: 'show',
            value: function show() {
                this.visible = true;
                this.container.classList.remove('dplayer-hotkey-panel-hide');
            }
        },
        {
            key: 'parse',
            value: function parse(keyCode) {
                if (this.visible) {
                    var all = Array.from(this.container.getElementsByClassName('shortcuts-enabled'));
                    all.forEach(function (div) {
                        var child = parseInt(div.getAttribute('data-keyCode'));
                        if (child === keyCode) {
                            div.animate([
                                { backgroundColor: 'var(--dplayer-simple-keyboard-keys-bk-available)' },
                                { backgroundColor: 'var(--dplayer-simple-keyboard-keys-bk-pressed)' }
                            ], 150);
                        }
                    });
                }
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.visible = false;
                this.container.classList.add('dplayer-hotkey-panel-hide');
            }
        },
        {
            key: 'triggle',
            value: function triggle() {
                if (this.container.classList.contains('dplayer-hotkey-panel-hide')) {
                    this.show();
                } else {
                    this.hide();
                }
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                this.keyboard.destroy();
                this.destroyed = true;
            }
        }
    ]);
    return HotkeyPanel;
}();
export default HotkeyPanel;