function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
var Bezel = function () {
    function Bezel(container) {
        _classCallCheck(this, Bezel);
        this.container = container;
        this.ClickFunctionHandler = this.ClickFunction.bind(this);
        this.container.addEventListener('animationend', this.ClickFunctionHandler);
    }
    _createClass(Bezel, [
        {
            key: 'ClickFunction',
            value: function ClickFunction() {
                this.container.classList.remove('dplayer-bezel-transition');
            }
        },
        {
            key: 'switch',
            value: function _switch(icon) {
                this.container.innerHTML = icon;
                this.container.classList.add('dplayer-bezel-transition');
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                this.container.removeEventListener('animationend', this.ClickFunctionHandler);
                this.destroyed = true;
            }
        }
    ]);
    return Bezel;
}();
export default Bezel;