function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
var Subtitles = function () {
    function Subtitles(player, container, options, events) {
        _classCallCheck(this, Subtitles);
        this.player = player;
        this.container = container;
        this.video = player.video;
        this.options = options;
        this.events = events;
        this.subtitles = options.url;
        this.multiple = options.url.length > 2;
        this.init();
    }
    _createClass(Subtitles, [
        {
            key: 'init',
            value: function init() {
                var _this = this;
                switch (this.options.type) {
                case 'webvtt':
                    if (this.multiple) {
                        this.player.template.mask.addEventListener('click', function () {
                            _this.hideModal();
                        });
                        var _loop = function _loop(i) {
                            _this.player.template.subtitlesItem[i].addEventListener('click', function () {
                                if (_this.subtitles[i].lang === 'off') {
                                    if (!_this.container.classList.contains('dplayer-subtitles-hide')) {
                                        _this.hide();
                                    }
                                } else {
                                    if (_this.container.classList.contains('dplayer-subtitles-hide')) {
                                        _this.show();
                                    }
                                    if (_this.player.options.subtitle.index !== i) {
                                        _this.player.template.subtitle.innerHTML = '<p></p>';
                                        _this.player.template.subtrack.src = _this.player.template.subtitlesItem[i].dataset.subtitle;
                                        _this.player.options.subtitle.index = i;
                                        _this.events.trigger('subtitle_change');
                                    }
                                }
                                _this.hideModal();
                            });
                        };
                        for (var i = 0; i < this.player.template.subtitlesItem.length; i++) {
                            _loop(i);
                        }
                    }
                    this.container.style.fontSize = this.options.fontSize;
                    this.container.style.bottom = this.options.bottom;
                    this.container.style.color = this.options.color;
                    if (typeof this.video === 'undefined') {
                        console.error('[CRITICAL] BUG in Subtitle!');
                        return;
                    }
                    if (this.video.textTracks && this.video.textTracks[0]) {
                        var track = this.video.textTracks[0];
                        track.oncuechange = function () {
                            var cue = track.activeCues[0];
                            _this.container.innerHTML = '';
                            if (cue) {
                                var template = document.createElement('div');
                                template.appendChild(cue.getCueAsHTML());
                                var trackHtml = template.innerHTML.split(/\r?\n/).map(function (item) {
                                    return '<p>'.concat(item, '</p>');
                                }).join('');
                                _this.container.innerHTML = trackHtml;
                            }
                            _this.events.trigger('subtitle_change');
                        };
                    }
                    if (!this.player.user.get('subtitle')) {
                        this.hide();
                    }
                    break;
                case 'ass':
                    if (this.multiple) {
                        console.error('Currently only webvtt type is supported by multiple Subtitles!');
                        return;
                    }
                    if (window.ass) {
                        var options = {
                            video: this.player.video,
                            subUrl: this.options.url
                        };
                        window.ass(options, this.player, function () {
                            if (!_this.player.user.get('subtitle')) {
                                _this.hide();
                            }
                        }, function (SO) {
                            _this.instance = SO;
                        });
                    } else {
                        this.player.notice('Error: Can\'t find ass support.', { warn: true });
                    }
                    break;
                default:
                    console.warn('Not supported Subtitle type: '.concat(this.options.type));
                    break;
                }
            }
        },
        {
            key: 'showModal',
            value: function showModal() {
                if (this.multiple) {
                    this.player.template.subtitlesBox.classList.add('dplayer-subtitles-box-open');
                    this.player.template.mask.classList.add('dplayer-mask-show');
                    this.player.controller.disableAutoHide = true;
                }
            }
        },
        {
            key: 'hideModal',
            value: function hideModal() {
                if (this.multiple) {
                    this.player.template.subtitlesBox.classList.remove('dplayer-subtitles-box-open');
                    this.player.template.mask.classList.remove('dplayer-mask-show');
                    this.player.controller.disableAutoHide = false;
                }
            }
        },
        {
            key: 'hide',
            value: function hide() {
                if (this.multiple) {
                    this.container.classList.add('dplayer-subtitles-hide');
                    this.events.trigger('subtitle_hide');
                } else {
                    switch (this.options.type) {
                    case 'webvtt':
                        this.container.classList.add('dplayer-subtitles-hide');
                        this.events.trigger('subtitle_hide');
                        break;
                    case 'ass':
                        if (window.ass && this.instance) {
                            this.instance.canvas.classList.add('hide-force');
                            this.events.trigger('subtitle_hide');
                        }
                        break;
                    default:
                        console.warn('Not supported Subtitle type: '.concat(this.options.type));
                        break;
                    }
                }
            }
        },
        {
            key: 'show',
            value: function show() {
                if (this.multiple) {
                    this.container.classList.remove('dplayer-subtitles-hide');
                    this.events.trigger('subtitle_show');
                } else {
                    switch (this.options.type) {
                    case 'webvtt':
                        this.container.classList.remove('dplayer-subtitles-hide');
                        this.events.trigger('subtitle_show');
                        break;
                    case 'ass':
                        if (window.ass && this.instance) {
                            this.instance.canvas.classList.remove('hide-force');
                            this.events.trigger('subtitle_show');
                        }
                        break;
                    default:
                        console.warn('Not supported Subtitle type: '.concat(this.options.type));
                        break;
                    }
                }
            }
        },
        {
            key: 'toggleModal',
            value: function toggleModal() {
                if (this.player.template.subtitlesBox.classList.contains('dplayer-subtitles-box-open')) {
                    this.hideModal();
                } else {
                    this.showModal();
                }
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.multiple) {
                    this.toggleModal();
                } else {
                    switch (this.options.type) {
                    case 'webvtt':
                        if (this.container.classList.contains('dplayer-subtitles-hide')) {
                            this.show();
                        } else {
                            this.hide();
                        }
                        break;
                    case 'ass':
                        if (window.ass && this.instance) {
                            if (this.instance.canvas.classList.contains('hide-force')) {
                                this.show();
                            } else {
                                this.hide();
                            }
                        }
                        break;
                    default:
                        console.warn('Not supported Subtitle type: '.concat(this.options.type));
                        break;
                    }
                }
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                if (this.instance) {
                    this.instance.dispose();
                }
            }
        }
    ]);
    return Subtitles;
}();
export default Subtitles;