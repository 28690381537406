function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
    throw new TypeError('Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.');
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o)
        return;
    if (typeof o === 'string')
        return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === 'Object' && o.constructor)
        n = o.constructor.name;
    if (n === 'Map' || n === 'Set')
        return Array.from(o);
    if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
        return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length)
        len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
    }
    return arr2;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== 'undefined' && arr[Symbol.iterator] || arr['@@iterator'];
    if (_i == null)
        return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
            _arr.push(_s.value);
            if (i && _arr.length === i)
                break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally {
        try {
            if (!_n && _i['return'] != null)
                _i['return']();
        } finally {
            if (_d)
                throw _e;
        }
    }
    return _arr;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr))
        return arr;
}
function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
function i18n(lang) {
    var _this = this;
    this.lang = lang;
    this.fallbackLang = this.lang.includes('-') ? this.lang.split('-')[0] : this.lang;
    this.translate = function (key, replacement) {
        if (!key) {
            console.error('key for translation not set!');
            return;
        }
        if (typeof key !== 'string') {
            console.error('key for translation is not a string, but a \''.concat(_typeof(key), '\'!'));
            return;
        }
        key = key.toLowerCase();
        var result = null;
        if (tranTxt[_this.lang] && tranTxt[_this.lang][key]) {
            result = tranTxt[_this.lang][key];
        } else if (tranTxt[_this.fallbackLang] && tranTxt[_this.fallbackLang][key]) {
            result = tranTxt[_this.fallbackLang][key];
        } else {
            result = standard[key];
        }
        if (typeof model[key] === 'undefined') {
            console.error('Couldn\'t find key: '.concat(key));
            return 'ERROR';
        }
        if (model[key].length > 0 && replacement) {
            result = result.replace(model[key][0].symbol, replacement);
        }
        return result;
    };
    this.checkPresentTranslations = checkPresentTranslations;
}
var model = {
    'danmaku-loading': [],
    top: [],
    bottom: [],
    rolling: [],
    'input-danmaku-enter': [],
    'about-author': [],
    'dplayer-feedback': [],
    'about-dplayer': [],
    'hotkey-info': [],
    loop: [],
    'speed-raw': [],
    speed: [{
            symbol: '%s',
            name: 'Speed',
            example: '125%'
        }],
    'opacity-danmaku': [],
    normal: [],
    'please-input-danmaku': [],
    'set-danmaku-color': [],
    'set-danmaku-type': [],
    'show-danmaku': [],
    'video-failed': [],
    'danmaku-failed': [],
    'danmaku-send-failed': [],
    'switching-quality': [{
            symbol: '%q',
            name: 'Quality',
            example: '720p'
        }],
    'switched-quality': [{
            symbol: '%q',
            name: 'Quality',
            example: '720p'
        }],
    ff: [{
            symbol: '%t',
            name: 'Time',
            example: '5 %m 10 %s'
        }],
    rew: [{
            symbol: '%t',
            name: 'Time',
            example: '5 %m 10 %s'
        }],
    seconds: [{
            symbol: '%s',
            name: 'Seconds',
            example: '15'
        }],
    minutes: [{
            symbol: '%m',
            name: 'Minutes',
            example: '3'
        }],
    hours: [{
            symbol: '%h',
            name: 'Hours',
            example: '4'
        }],
    'unlimited-danmaku': [],
    'send-danmaku': [],
    setting: [],
    fullscreen: [],
    'web-fullscreen': [],
    send: [],
    subtitle: [],
    'subtitle-off': [],
    'saved-screenshot': [{
            symbol: '%n',
            name: 'Screenshot name',
            example: 'Awesome_Video_24_04.png'
        }],
    'screenshot-raw': [],
    airplay: [],
    chromecast: [],
    'show-subs': [],
    'hide-subs': [],
    volume: [],
    live: [],
    'video-info': [],
    on: [],
    off: [],
    toggleplayer: [],
    left: [],
    right: [],
    up: [],
    down: [],
    cancelbothfullscreen: [],
    togglefullscreen: [],
    togglewebfullscreen: [],
    mute: [],
    screenshot: [],
    nextchapter: [],
    previouschapter: [],
    changeloop: [],
    speedup: [],
    speeddown: [],
    speednormal: [],
    'hotkey-disabled': [],
    skipped_chapter: [{
            symbol: '%c',
            name: 'Chapter name',
            example: 'Opening'
        }],
    skip: [],
    skip_chapter: [{
            symbol: '%c',
            name: 'Chapter name',
            example: 'Opening'
        }],
    cancel: [],
    en: [],
    'zh-cn': [],
    'zh-tw': [],
    'ko-kr': [],
    de: []
};
var standard = require('../translations/en.json');
var tranTxt = {
    en: standard,
    'zh-cn': require('../translations/zh-cn.json'),
    'zh-tw': require('../translations/zh-tw.json'),
    'ko-kr': require('../translations/ko-kr.json'),
    de: require('../translations/de.json')
};
function checkPresentTranslations(singleLanguage, debug) {
    if (!singleLanguage || debug) {
        Object.keys(tranTxt).forEach(function (language) {
            checkSingleLanguage(language);
        });
    } else {
        checkSingleLanguage(singleLanguage);
    }
}
function checkSingleLanguage(language) {
    var translation = tranTxt[language];
    Object.entries(model).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2), key = _ref2[0], value = _ref2[1];
        if (!translation[key]) {
            console.info('Translation for '.concat(language, ' has no key ').concat(key, '!'));
        } else if (value.length > 0) {
            value.forEach(function (_ref3) {
                var symbol = _ref3.symbol, name = _ref3.name, example = _ref3.example;
                if (!translation[key].includes(symbol)) {
                    console.info('Translation for '.concat(language, ' misses the symbol ').concat(symbol, ' in ').concat(key, ', it represents ').concat(name, ', example value ').concat(example, '!'));
                }
            });
        }
    });
}
export default i18n;