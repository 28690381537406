function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
var Thumbnails = function () {
    function Thumbnails(options) {
        var _this = this;
        _classCallCheck(this, Thumbnails);
        this.container = options.container;
        this.barWidth = options.barWidth;
        this.size = 160;
        this.events = options.events;
        if (options.url === 'API') {
            this.api.requestThumbnail(function (response) {
                _this.container.style.backgroundImage = 'url(\''.concat(response.url, '\')');
                _this.container.style.backgroundColor = 'rgba(0, 0, 0, 0)';
                _this.container.classList.remove('hidden');
            });
        } else {
            this.container.style.backgroundImage = 'url(\''.concat(options.url, '\')');
            this.container.style.backgroundColor = 'rgba(0, 0, 0, 0)';
            this.container.classList.remove('hidden');
        }
    }
    _createClass(Thumbnails, [
        {
            key: 'resize',
            value: function resize(ratio, barWrapWidth) {
                var height = ratio * this.size;
                var width = this.size;
                this.container.style.width = ''.concat(width, 'px');
                this.container.style.height = ''.concat(height, 'px');
                this.container.style.top = ''.concat(-height + 2, 'px');
                this.barWidth = barWrapWidth;
            }
        },
        {
            key: 'show',
            value: function show() {
                this.events && this.events.trigger('thumbnails_show');
            }
        },
        {
            key: 'move',
            value: function move(position) {
                this.container.style.backgroundPosition = '-'.concat((Math.ceil(position / this.barWidth * 100) - 1) * this.size, 'px 0');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.events && this.events.trigger('thumbnails_hide');
            }
        }
    ]);
    return Thumbnails;
}();
export default Thumbnails;