function _toConsumableArray(arr) {
    return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread();
}
function _nonIterableSpread() {
    throw new TypeError('Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.');
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o)
        return;
    if (typeof o === 'string')
        return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === 'Object' && o.constructor)
        n = o.constructor.name;
    if (n === 'Map' || n === 'Set')
        return Array.from(o);
    if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
        return _arrayLikeToArray(o, minLen);
}
function _iterableToArray(iter) {
    if (typeof Symbol !== 'undefined' && iter[Symbol.iterator] != null || iter['@@iterator'] != null)
        return Array.from(iter);
}
function _arrayWithoutHoles(arr) {
    if (Array.isArray(arr))
        return _arrayLikeToArray(arr);
}
function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length)
        len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
    }
    return arr2;
}
function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
import defaultApiBackend from './api.js';
import utils from './utils.js';
export default function (options, player) {
    var defaultOption = {
        container: options.element || document.getElementsByClassName('dplayer')[0],
        live: false,
        autoplay: false,
        themeName: 'standard',
        disableDarkMode: false,
        loop: false,
        lang: (navigator.language || navigator.browserLanguage).toLowerCase(),
        screenshot: true,
        airplay: 'vendor',
        chromecast: 'vendor',
        hotkey: true,
        advancedHotkeys: false,
        preload: 'metadata',
        volume: 0.7,
        playbackSpeed: [
            0.5,
            0.75,
            1,
            1.25,
            1.5,
            2
        ],
        apiBackend: defaultApiBackend,
        video: {},
        contextmenu: [{
                text: 'DPlayer v'.concat(DPLAYER_VERSION),
                link: 'https://github.com/Totto16/DPlayer'
            }],
        fullScreenPolicy: 'OnlyNormal',
        highlightSkipArray: [
            /^\s*Opening\s*\d*$/i,
            /^\s*Ending\s*\d*$/i,
            /^\s*OP\s*\d*$/i,
            /^\s*ED\s*\d*$/i,
            /^\s*Intro\s*$/i,
            /^\s*Outro\s*$/i,
            /^\s*Credits\s*$/i,
            /^\s*Pause\s*$/i
        ],
        highlightSkipMode: 'smoothPrompt',
        highlightSkip: false,
        skipDelay: 5000,
        hardSkipHighlights: false,
        mutex: true,
        pluginOptions: {
            hls: {},
            flv: {},
            dash: {},
            webtorrent: {},
            ass: {}
        },
        balloon: false
    };
    for (var defaultKey in defaultOption) {
        if (defaultOption.hasOwnProperty(defaultKey) && !options.hasOwnProperty(defaultKey)) {
            options[defaultKey] = defaultOption[defaultKey];
        }
    }
    if (options.video && !options.video.type) {
        options.video.type = 'auto';
    }
    if (_typeof(options.danmaku) === 'object' && options.danmaku) {
        options.danmaku.user = options.danmaku.user || 'DIYgod';
    }
    if (options.subtitle) {
        if (!options.subtitle.type) {
            options.subtitle.type = 'webvtt';
            if (/ass(#|\?|$)/i.exec(options.subtitle.url) || /ssa(#|\?|$)/i.exec(options.subtitle.url)) {
                options.subtitle.type = 'ass';
            }
        }
        options.subtitle.fontSize = options.subtitle.fontSize || '20px';
        options.subtitle.bottom = options.subtitle.bottom || '40px';
        options.subtitle.color = options.subtitle.color || '#fff';
        if (!Array.isArray(options.subtitle.url)) {
            options.subtitle.url = [{
                    subtitle: options.subtitle.url,
                    lang: null,
                    name: 'default'
                }];
        }
        var offSubtitle = {
            subtitle: '',
            lang: 'off'
        };
        options.subtitle.url.push(offSubtitle);
        if (options.subtitle.defaultSubtitle) {
            if (typeof options.subtitle.defaultSubtitle === 'string') {
                options.subtitle.index = options.subtitle.url.findIndex(function (sub) {
                    return sub.lang === options.subtitle.defaultSubtitle || sub.name === options.subtitle.defaultSubtitle;
                });
            } else if (typeof options.subtitle.defaultSubtitle === 'number') {
                options.subtitle.index = options.subtitle.defaultSubtitle;
            } else {
                console.warn('Invalid default Subtitle Index Provided, setting it to default, meaning \'off\'');
                options.subtitle.index = -1;
            }
        }
        if (options.subtitle.index === -1 || !options.subtitle.index || options.subtitle.index > options.subtitle.url.length - 1) {
            options.subtitle.index = options.subtitle.url.findIndex(function (sub) {
                return sub.lang === options.lang;
            });
        }
        if (options.subtitle.index === -1) {
            options.subtitle.index = options.subtitle.url.length - 1;
        }
    }
    if (options.video && !options.video.defaultQuality) {
        options.video.defaultQuality = 0;
    }
    if (options.video.quality) {
        options.video.url = options.video.quality[options.video.defaultQuality].url;
    }
    if (options.lang) {
        options.lang = options.lang.toLowerCase();
    }
    if (options.airplay) {
        options.airplay = typeof options.airplay === 'string' ? options.airplay === 'vendor' ? utils.supportsAirplay() : false : options.airplay;
    }
    if (options.chromecast) {
        options.chromecast = typeof options.chromecast === 'string' ? options.chromecast === 'vendor' ? utils.supportsChromeCast() : false : options.chromecast;
    }
    if (options.highlight) {
        options.highlights = {
            marker: options.highlight,
            mode: 'auto'
        };
        options.highlight = null;
    }
    if (options.highlights && options.highlights.vtt) {
        utils.parseVtt(options.highlights.vtt, function (marker) {
            if (!player.options.highlights) {
                player.options.highlights = {};
                console.warn('Something really weird is going on, there is a bug somewhere! Please report that!');
            }
            player.options.highlights.marker = marker;
            player.events.trigger('highlight_change');
        }, 0, options);
    }
    if (options.highlights && !options.highlights.mode) {
        options.highlights.mode = 'auto';
    }
    if (options.highlights && options.highlights.marker && options.highlights.marker.length <= 0) {
        options.highlights = null;
    }
    if (typeof options.highlightSkipMode !== 'undefined') {
        switch (options.highlightSkipMode.toString().toLowerCase()) {
        case 'smoothprompt':
            break;
        case 'immediately':
            break;
        case 'smoothcancelprompt':
            break;
        case 'always':
            break;
        case '0':
            options.highlightSkipMode = 'smoothPrompt';
            break;
        case '1':
            options.highlightSkipMode = 'immediately';
            break;
        case '2':
            options.highlightSkipMode = 'smoothCancelPrompt';
            break;
        case '3':
            options.highlightSkipMode = 'always';
            break;
        default:
            console.warn('\''.concat(options.highlightSkipMode, '\' highlightSkipMode option not available, set to default!'));
            options.highlightSkipMode = defaultOption.highlightSkipMode;
            break;
        }
        if (!options.highlightSkipArray) {
            options.highlightSkipArray = defaultOption.highlightSkipMode;
        }
        var temp = options.highlightSkipArray;
        options.highlightSkipArray = [];
        temp.forEach(function (a) {
            if (a === '*') {
                var _options$highlightSki;
                (_options$highlightSki = options.highlightSkipArray).push.apply(_options$highlightSki, _toConsumableArray(defaultOption.highlightSkipArray));
                return;
            }
            if (!(a instanceof RegExp)) {
                var _temp;
                try {
                    _temp = new RegExp(a, 'i');
                    options.highlightSkipArray.push(_temp);
                    return;
                } catch (e) {
                    console.warn('String converted to RegExp not Valid, skipped: \''.concat(a, '\''));
                    return;
                }
            }
            options.highlightSkipArray.push(a);
        });
    }
    if (options.theme) {
        console.warn('Setting the Theme in this way is deprecated!');
        var style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = '.dplayer { --dplayer-theme-color:'.concat(options.theme, ' !important; };');
        document.head.appendChild(style);
        options.theme = null;
    }
    switch (options.fullScreenPolicy.toString().toLowerCase()) {
    case 'onlynormal':
        break;
    case 'onlyweb':
        break;
    case 'both':
        break;
    case '0':
        options.fullScreenPolicy = 'OnlyNormal';
        break;
    case '1':
        options.fullScreenPolicy = 'OnlyWeb';
        break;
    case '2':
        options.fullScreenPolicy = 'Both';
        break;
    default:
        console.warn('\''.concat(options.fullScreenPolicy, '\' fullScreenPolicy option not available, set to default!'));
        options.fullScreenPolicy = defaultOption.fullScreenPolicy;
        break;
    }
    options.contextmenu = [
        {
            key: 'video-info',
            click: function click(player) {
                player.hotkeyPanel.hide();
                player.infoPanel.triggle();
            }
        },
        {
            key: 'hotkey-info',
            click: function click(player) {
                if (player.options.hotkey) {
                    player.infoPanel.hide();
                    player.hotkeyPanel.triggle();
                } else {
                    player.notice(player.translate('hotkey_disabled'));
                }
            }
        }
    ].concat(options.contextmenu);
    return options;
};