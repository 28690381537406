function _typeof(obj) {
    '@babel/helpers - typeof';
    return _typeof = 'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && 'function' == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj;
    }, _typeof(obj);
}
import axios from 'axios';
import api from './api';
var isMobile = /mobile/i.test(window.navigator.userAgent);
var isChrome = /chrome/i.test(window.navigator.userAgent);
var isSafari = /safari/i.test(window.navigator.userAgent);
var isFirefox = /firefox/i.test(window.navigator.userAgent);
var utils = {
    secondToTime: function secondToTime(second) {
        var delimiter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ':';
        second = second || 0;
        if (second === 0 || second === Infinity || second.toString() === 'NaN') {
            return '00'.concat(delimiter, '00');
        }
        var add0 = function add0(num) {
            return num < 10 ? '0' + num : '' + num;
        };
        var hour = Math.floor(second / 3600);
        var min = Math.floor((second - hour * 3600) / 60);
        var sec = Math.floor(second - hour * 3600 - min * 60);
        return (hour > 0 ? [
            hour,
            min,
            sec
        ] : [
            min,
            sec
        ]).map(add0).join(delimiter);
    },
    getElementViewLeft: function getElementViewLeft(element) {
        var actualLeft = element.offsetLeft;
        var current = element.offsetParent;
        var elementScrollLeft = document.body.scrollLeft + document.documentElement.scrollLeft;
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            while (current !== null) {
                actualLeft += current.offsetLeft;
                current = current.offsetParent;
            }
        } else {
            while (current !== null && current !== element) {
                actualLeft += current.offsetLeft;
                current = current.offsetParent;
            }
        }
        return actualLeft - elementScrollLeft;
    },
    getBoundingClientRectViewLeft: function getBoundingClientRectViewLeft(element) {
        var scrollTop = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
        if (element.getBoundingClientRect) {
            if (typeof this.getBoundingClientRectViewLeft.offset !== 'number') {
                var temp = document.createElement('div');
                temp.style.cssText = 'position:absolute;top:0;left:0;';
                document.body.appendChild(temp);
                this.getBoundingClientRectViewLeft.offset = -temp.getBoundingClientRect().top - scrollTop;
                document.body.removeChild(temp);
                temp = null;
            }
            var rect = element.getBoundingClientRect();
            var offset = this.getBoundingClientRectViewLeft.offset;
            return rect.left + offset;
        } else {
            return this.getElementViewLeft(element);
        }
    },
    getScrollPosition: function getScrollPosition() {
        return {
            left: window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0,
            top: window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
        };
    },
    setScrollPosition: function setScrollPosition(_ref) {
        var _ref$left = _ref.left, left = _ref$left === void 0 ? 0 : _ref$left, _ref$top = _ref.top, top = _ref$top === void 0 ? 0 : _ref$top;
        if (this.isFirefox) {
            document.documentElement.scrollLeft = left;
            document.documentElement.scrollTop = top;
        } else {
            window.scrollTo(left, top);
        }
    },
    isMobile: isMobile,
    isFirefox: isFirefox,
    isChrome: isChrome,
    isSafari: isSafari,
    storage: {
        set: function set(key, value) {
            localStorage.setItem(key, value);
        },
        get: function get(key) {
            return localStorage.getItem(key);
        }
    },
    encodeValueAsObject: function encodeValueAsObject(val) {
        return {
            value: val.toString(),
            type: _typeof(val)
        };
    },
    decodeValueFromObject: function decodeValueFromObject(obj) {
        var value = obj.value, type = obj.type;
        if (type === 'number') {
            return parseFloat(value);
        } else if (type === 'string') {
            return value;
        } else if (type === 'object') {
            return JSON.parse(value);
        } else {
            return null;
        }
    },
    supportsAirplay: function supportsAirplay() {
        return isSafari && !isChrome && !isFirefox;
    },
    supportsChromeCast: function supportsChromeCast() {
        return isChrome && !isSafari && !isFirefox;
    },
    nameMap: {
        dragStart: isMobile ? 'touchstart' : 'mousedown',
        dragMove: isMobile ? 'touchmove' : 'mousemove',
        dragEnd: isMobile ? 'touchend' : 'mouseup'
    },
    color2Number: function color2Number(color) {
        if (color[0] === '#') {
            color = color.substr(1);
        }
        if (color.length === 3) {
            color = ''.concat(color[0]).concat(color[0]).concat(color[1]).concat(color[1]).concat(color[2]).concat(color[2]);
        }
        return parseInt(color, 16) + 0 & 16777215;
    },
    number2Color: function number2Color(number) {
        return '#' + ('00000' + number.toString(16)).slice(-6);
    },
    number2Type: function number2Type(number) {
        switch (number) {
        case 0:
            return 'right';
        case 1:
            return 'top';
        case 2:
            return 'bottom';
        default:
            return 'right';
        }
    },
    deepCopyObject: function deepCopyObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    parseVtt: function parseVtt(vtt_url, callback) {
        var _this = this;
        var startOrEnd = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
        var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
        if (vtt_url === 'API' && options !== null) {
            var video_url = new URL(options.video.url);
            var parameter = options.video.type === 'hls' ? video_url.pathname.substring(0, video_url.pathname.lastIndexOf('/')).split('/').filter(function (str) {
                return str !== '';
            }).join('-') : video_url.pathname.substring(video_url.pathname.lastIndexOf('/') + 1);
            api.backend({
                url: options.API_URL,
                query: {
                    version: '1',
                    get: 'reference',
                    type: 'vtt',
                    parameter: parameter,
                    mode: 'regex'
                }
            }).then(function (data) {
                if (data !== undefined && data !== null) {
                    _this.parseVtt(data, callback, startOrEnd);
                } else {
                }
            })['catch'](function (error) {
                console.error('Error in API request for the Vtt Url!', error);
                return null;
            });
            return 'processing API request';
        } else if (vtt_url === 'API' && options.API_URL === null) {
            console.warn('Tried to pass \'API\' as vtt_url, but didn\'t specify \'API_URL\'!');
            return;
        }
        var marker = [];
        axios.get(vtt_url).then(function (response) {
            var status_ok = [
                200,
                301,
                302
            ];
            if (!status_ok.includes(response.status)) {
                throw new Error('Incorrect Status');
            }
            if (response.headers['content-type'] !== 'text/vtt') {
                throw new Error('Incorrect content-type, should be text/vtt');
            }
            if (!response.data) {
                throw new Error('Empty Web-Vtt');
            }
            var data = response.data.replaceAll('\r', '').split('\n').filter(function (a) {
                return a.length > 0;
            });
            data.forEach(function (text, i) {
                if (text.includes('-->')) {
                    var mark = {};
                    mark.text = data.length > i + 1 ? data[i + 1] : 'Error';
                    var endTime = text.split('-->')[startOrEnd].trim();
                    var multiplier = [
                        1,
                        60,
                        60 * 60,
                        60 * 60 * 24
                    ];
                    var index = 0;
                    var time = endTime.split(':').reverse().reduce(function (sum, act) {
                        var num = isNaN(parseFloat(act)) ? 0 : parseFloat(act);
                        num = num * multiplier[index];
                        index++;
                        return num + sum;
                    }, 0);
                    mark.time = time;
                    marker.push(mark);
                }
            });
        })['catch'](function (error) {
            console.error('Couldn\'t parse Vtt Url! Fetching Error!', error);
            return null;
        })['finally'](function () {
            callback(marker);
        });
        return 'processing';
    }
};
export default utils;