function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        enumerableOnly && (symbols = symbols.filter(function (sym) {
            return Object.getOwnPropertyDescriptor(object, sym).enumerable;
        })), keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
            _defineProperty(target, key, source[key]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
import utils from './utils';
import handleOption from './options';
import i18n from './i18n';
import Template from './template';
import Icons from './icons';
import Danmaku from './danmaku';
import Events from './events';
import FullScreen from './fullscreen';
import User from './user';
import Subtitles from './subtitles';
import Bar from './bar';
import Timer from './timer';
import Bezel from './bezel';
import Controller from './controller';
import Setting from './setting';
import Comment from './comment';
import HotKey from './hotkey';
import ContextMenu from './contextmenu';
import InfoPanel from './info-panel';
import HotkeyPanel from './hotkey-panel';
import tplVideo from '../template/video.art';
var index = 0;
window.DPLAYER_INSTANCES = [];
var DPlayer = function () {
    function DPlayer(options) {
        var _this = this;
        _classCallCheck(this, DPlayer);
        try {
            this.state = {
                code: 1,
                message: 'running',
                data: 'constructor'
            };
            this.options = handleOption(_objectSpread({ preload: options && options.video && options.video.type === 'webtorrent' ? 'none' : 'metadata' }, options), this);
            if (this.options.video.quality) {
                this.qualityIndex = this.options.video.defaultQuality;
                this.quality = this.options.video.quality[this.options.video.defaultQuality];
            }
            this.languageFeatures = new i18n(this.options.lang);
            this.translate = this.languageFeatures.translate;
            this.languageFeatures.checkPresentTranslations(this.options.lang, true);
            this.events = new Events();
            this.user = new User(this);
            this.container = this.options.container;
            this.container.classList.add('dplayer');
            this.container.classList.add(this.options.themeName);
            if (this.options.disableDarkMode) {
                this.container.classList.add('nodark');
            }
            if (!this.options.danmaku) {
                this.container.classList.add('dplayer-no-danmaku');
            }
            if (this.options.live) {
                this.container.classList.add('dplayer-live');
            } else {
                this.container.classList.remove('dplayer-live');
            }
            if (utils.isMobile) {
                this.container.classList.add('dplayer-mobile');
            }
            this.arrow = this.container.offsetWidth <= 500;
            if (this.arrow) {
                this.container.classList.add('dplayer-arrow');
            }
            this.template = new Template({
                container: this.container,
                player: this,
                options: this.options,
                index: index,
                translate: this.translate
            });
            this.video = this.template.video;
            this.bar = new Bar(this.template, this);
            this.bezel = new Bezel(this.template.bezel);
            this.fullScreen = new FullScreen(this);
            this.controller = new Controller(this);
            if (this.options.danmaku) {
                this.danmaku = new Danmaku({
                    player: this,
                    container: this.template.danmaku,
                    opacity: this.user.get('opacity'),
                    callback: function callback() {
                        setTimeout(function () {
                            _this.template.danmakuLoading.style.display = 'none';
                            if (_this.options.autoplay) {
                                _this.play();
                            }
                        }, 0);
                    },
                    error: function error(msg) {
                        _this.notice(msg);
                    },
                    apiBackend: this.options.apiBackend,
                    borderColor: 'var(--dplayer-theme-color)',
                    height: this.arrow ? 24 : 30,
                    time: function time() {
                        return _this.video.currentTime;
                    },
                    unlimited: this.user.get('unlimited'),
                    api: {
                        id: this.options.danmaku.id,
                        address: this.options.danmaku.api,
                        token: this.options.danmaku.token,
                        maximum: this.options.danmaku.maximum,
                        addition: this.options.danmaku.addition,
                        user: this.options.danmaku.user,
                        speedRate: this.options.danmaku.speedRate
                    },
                    events: this.events,
                    translate: function translate(msg) {
                        return _this.translate(msg);
                    }
                });
                this.comment = new Comment(this);
            }
            this.setting = new Setting(this);
            this.plugins = {};
            this.docClickFun = function () {
                _this.focus = false;
            };
            this.containerClickFun = function () {
                _this.focus = true;
            };
            document.addEventListener('click', this.docClickFun, true);
            this.container.addEventListener('click', this.containerClickFun, true);
            this.paused = true;
            this.timer = new Timer(this);
            this.hotkey = new HotKey(this);
            this.infoPanel = new InfoPanel(this);
            this.hotkeyPanel = new HotkeyPanel(this);
            this.contextmenu = new ContextMenu(this);
            this.initVideo(this.video, this.quality && this.quality.type || this.options.video.type);
            if (!this.danmaku && this.options.autoplay) {
                this.play();
            }
            index++;
            window.DPLAYER_INSTANCES.push(this);
            this.state = {
                code: 0,
                message: 'ok'
            };
        } catch (error) {
            this.state = {
                code: 3,
                message: 'error',
                data: error
            };
            console.error('DPLAYER initialization failed with:', error);
        }
    }
    _createClass(DPlayer, [
        {
            key: 'seek',
            value: function seek(time) {
                var silent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
                time = Math.max(isNaN(time) ? 0 : time, 0);
                if (this.video.duration) {
                    time = Math.min(time, this.video.duration);
                }
                if (this.video.currentTime < time && !silent) {
                    this.notice(''.concat(this.translate('ff', this.formatTime(time - this.video.currentTime))));
                } else if (this.video.currentTime > time && !silent) {
                    this.notice(''.concat(this.translate('rew', this.formatTime(this.video.currentTime - time))));
                }
                this.video.currentTime = time;
                if (this.danmaku) {
                    this.danmaku.seek();
                }
                this.bar.set('played', time / this.video.duration);
                this.controller.updateChapters({
                    time: time,
                    duration: this.video.duration
                }, this);
                this.template.ptime.innerHTML = utils.secondToTime(time);
            }
        },
        {
            key: 'formatTime',
            value: function formatTime(time) {
                if (time < 60) {
                    return this.translate('seconds', time.toFixed(0));
                } else if (time < 60 * 60) {
                    return ''.concat(this.translate('minutes', Math.floor(time / 60).toFixed(0)), ' ').concat(this.translate('seconds', (time % 60).toFixed(0)));
                } else if (time < 60 * 60 * 24) {
                    return ''.concat(this.translate('hours', Math.floor(time / (60 * 60)).toFixed(0)), ' ').concat(this.translate('minutes', Math.floor(time % (60 * 60) / 60).toFixed(0)), ' ').concat(this.translate('seconds', (time % 60).toFixed(0)));
                } else {
                    return ''.concat(Math.floor(time / (60 * 60 * 24)).toFixed(0), ' d ').concat(this.translate('hours', Math.floor(time % (60 * 60 * 24) / (60 * 60)).toFixed(0)), ' ').concat(this.translate('minutes', Math.floor(time % (60 * 60) / 60).toFixed(0)), ' ').concat(this.translate('seconds', (time % 60).toFixed(0)));
                }
            }
        },
        {
            key: 'play',
            value: function play(fromNative) {
                var _this2 = this;
                this.paused = false;
                if (this.video.paused && !utils.isMobile) {
                    this.bezel['switch'](Icons.play);
                }
                this.template.playButton.innerHTML = Icons.pause;
                this.template.mobilePlayButton.innerHTML = Icons.pause;
                if (!fromNative) {
                    var playedPromise = Promise.resolve(this.video.play());
                    playedPromise['catch'](function () {
                        _this2.pause();
                    }).then(function () {
                    });
                }
                this.timer.enable('loading');
                this.container.classList.remove('dplayer-paused');
                this.container.classList.add('dplayer-playing');
                if (this.danmaku) {
                    this.danmaku.play();
                }
                if (this.options.mutex) {
                    for (var i = 0; i < window.DPLAYER_INSTANCES.length; i++) {
                        if (this !== window.DPLAYER_INSTANCES[i]) {
                            window.DPLAYER_INSTANCES[i].pause();
                        }
                    }
                }
            }
        },
        {
            key: 'pause',
            value: function pause(fromNative) {
                this.paused = true;
                this.container.classList.remove('dplayer-loading');
                if (!this.video.paused && !utils.isMobile) {
                    this.bezel['switch'](Icons.pause);
                }
                this.template.playButton.innerHTML = Icons.play;
                this.template.mobilePlayButton.innerHTML = Icons.play;
                if (!fromNative) {
                    this.video.pause();
                }
                this.timer.disable('loading');
                this.container.classList.remove('dplayer-playing');
                this.container.classList.add('dplayer-paused');
                if (this.danmaku) {
                    this.danmaku.pause();
                }
            }
        },
        {
            key: 'switchVolumeIcon',
            value: function switchVolumeIcon() {
                if (this.volume() >= 0.75) {
                    this.template.volumeIcon.innerHTML = Icons.volumeUp;
                } else if (this.volume() > 0) {
                    this.template.volumeIcon.innerHTML = Icons.volumeDown;
                } else {
                    this.template.volumeIcon.innerHTML = Icons.volumeOff;
                }
            }
        },
        {
            key: 'volume',
            value: function volume(percentage, nostorage, nonotice) {
                percentage = parseFloat(percentage);
                if (!isNaN(percentage)) {
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    this.bar.set('volume', percentage);
                    var formatPercentage = ''.concat((percentage * 100).toFixed(0), '%');
                    this.template.volumeBarWrapWrap.dataset.balloon = formatPercentage;
                    if (!nostorage) {
                        this.user.set('volume', percentage);
                    }
                    if (!nonotice) {
                        this.notice(''.concat(this.translate('volume'), ' ').concat((percentage * 100).toFixed(0), '%'), { type: 'volume' });
                    }
                    this.video.volume = percentage;
                    if (this.video.muted) {
                        this.video.muted = false;
                    }
                    this.switchVolumeIcon();
                }
                return this.video.volume;
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.video.paused) {
                    this.play();
                } else {
                    this.pause();
                }
            }
        },
        {
            key: 'on',
            value: function on(name, callback) {
                var once = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
                var delayed = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
                return this.events.on(name, callback, once, delayed);
            }
        },
        {
            key: 'once',
            value: function once(name, callback) {
                var delayed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
                return this.events.once(name, callback, delayed);
            }
        },
        {
            key: 'switchVideo',
            value: function switchVideo(video, danmakuAPI) {
                this.pause();
                this.video.poster = video.pic ? video.pic : '';
                this.video.src = video.url;
                this.initMSE(this.video, video.type || 'auto');
                if (danmakuAPI) {
                    this.template.danmakuLoading.style.display = 'block';
                    this.bar.set('played', 0);
                    this.bar.set('loaded', 0);
                    this.template.ptime.innerHTML = '00:00';
                    this.template.danmaku.innerHTML = '';
                    if (this.danmaku) {
                        this.danmaku.reload({
                            id: danmakuAPI.id,
                            address: danmakuAPI.api,
                            token: danmakuAPI.token,
                            maximum: danmakuAPI.maximum,
                            addition: danmakuAPI.addition,
                            user: danmakuAPI.user
                        });
                    }
                }
            }
        },
        {
            key: 'initMSE',
            value: function initMSE(video, type) {
                var _this3 = this;
                this.type = type;
                if (this.options.video.customType && this.options.video.customType[type]) {
                    if (Object.prototype.toString.call(this.options.video.customType[type]) === '[object Function]') {
                        this.options.video.customType[type](this.video, this);
                    } else {
                        console.error('Illegal customType: '.concat(type));
                    }
                } else {
                    if (this.type === 'auto') {
                        if (/m3u8(#|\?|$)/i.exec(video.src)) {
                            this.type = 'hls';
                        } else if (/.flv(#|\?|$)/i.exec(video.src)) {
                            this.type = 'flv';
                        } else if (/.mpd(#|\?|$)/i.exec(video.src)) {
                            this.type = 'dash';
                        } else {
                            this.type = 'normal';
                        }
                    }
                    if (this.type === 'hls' && (video.canPlayType('application/x-mpegURL') || video.canPlayType('application/vnd.apple.mpegURL'))) {
                        this.type = 'normal';
                    }
                    switch (this.type) {
                    case 'hls':
                        if (window.Hls) {
                            if (window.Hls.isSupported()) {
                                var options = this.options.pluginOptions.hls;
                                var hls = new window.Hls(options);
                                this.plugins.hls = hls;
                                hls.loadSource(video.src);
                                hls.attachMedia(video);
                                this.events.on('destroy', function () {
                                    hls.destroy();
                                    delete _this3.plugins.hls;
                                });
                            } else {
                                this.notice('Error: Hls is not supported.', { warn: true });
                            }
                        } else {
                            this.notice('Error: Can\'t find Hls.', { warn: true });
                        }
                        break;
                    case 'flv':
                        if (window.flvjs) {
                            if (window.flvjs.isSupported()) {
                                var flvPlayer = window.flvjs.createPlayer(Object.assign(this.options.pluginOptions.flv.mediaDataSource || {}, {
                                    type: 'flv',
                                    url: video.src
                                }), this.options.pluginOptions.flv.config);
                                this.plugins.flvjs = flvPlayer;
                                flvPlayer.attachMediaElement(video);
                                flvPlayer.load();
                                this.events.on('destroy', function () {
                                    flvPlayer.unload();
                                    flvPlayer.detachMediaElement();
                                    flvPlayer.destroy();
                                    delete _this3.plugins.flvjs;
                                });
                            } else {
                                this.notice('Error: flvjs is not supported.', { warn: true });
                            }
                        } else {
                            this.notice('Error: Can\'t find flvjs.', { warn: true });
                        }
                        break;
                    case 'dash':
                        if (window.dashjs) {
                            var dashjsPlayer = window.dashjs.MediaPlayer().create().initialize(video, video.src, false);
                            var _options = this.options.pluginOptions.dash;
                            dashjsPlayer.updateSettings(_options);
                            this.plugins.dash = dashjsPlayer;
                            this.events.on('destroy', function () {
                                window.dashjs.MediaPlayer().reset();
                                delete _this3.plugins.dash;
                            });
                        } else {
                            this.notice('Error: Can\'t find dashjs.', { warn: true });
                        }
                        break;
                    case 'webtorrent':
                        if (window.WebTorrent) {
                            if (window.WebTorrent.WEBRTC_SUPPORT) {
                                this.container.classList.add('dplayer-loading');
                                var _options2 = this.options.pluginOptions.webtorrent;
                                var client = new window.WebTorrent(_options2);
                                this.plugins.webtorrent = client;
                                var torrentId = video.src;
                                video.src = '';
                                video.preload = 'metadata';
                                video.addEventListener('durationchange', function () {
                                    return _this3.container.classList.remove('dplayer-loading');
                                }, { once: true });
                                client.add(torrentId, function (torrent) {
                                    var file = torrent.files.find(function (file) {
                                        return file.name.endsWith('.mp4');
                                    });
                                    file.renderTo(_this3.video, {
                                        autoplay: _this3.options.autoplay,
                                        controls: false
                                    });
                                });
                                this.events.on('destroy', function () {
                                    client.remove(torrentId);
                                    client.destroy();
                                    delete _this3.plugins.webtorrent;
                                });
                            } else {
                                this.notice('Error: Webtorrent is not supported.', { warn: true });
                            }
                        } else {
                            this.notice('Error: Can\'t find Webtorrent.', { warn: true });
                        }
                        break;
                    }
                }
            }
        },
        {
            key: 'initVideo',
            value: function initVideo(video, type) {
                var _this4 = this;
                this.initMSE(video, type);
                this.on('durationchange', function () {
                    if (_this4.video.seekable.length > 1 || Math.abs(_this4.video.duration - (_this4.video.seekable.end(0) - _this4.video.seekable.start(0))) >= 0.5) {
                        console.warn('The Source of the video probably doesn\'t support byte ranges or the video format doesn\'t support seeking! The video isn\'t seekable the entire way!');
                    }
                    if (video.duration !== 1 && video.duration !== Infinity && video.duration) {
                        _this4.template.dtime.innerHTML = utils.secondToTime(video.duration);
                        _this4.events.trigger('progress');
                    }
                });
                this.lastRecieveTime;
                this.on('progress', function () {
                    if (!video.duration || video.buffered.length <= 0) {
                        return;
                    }
                    var ranges = [];
                    for (var i = 0; i < video.buffered.length; i++) {
                        var start = video.buffered.start(i);
                        var end = video.buffered.end(i);
                        ranges.push({
                            start: start,
                            end: end
                        });
                    }
                    var percentage = video.buffered.end(video.buffered.length - 1) / video.duration;
                    _this4.bar.set('loaded', {
                        ranges: ranges,
                        percentage: percentage
                    });
                });
                this.on('error', function () {
                    if (!_this4.video.error) {
                        return;
                    }
                    _this4.translate && _this4.notice && _this4.type !== 'webtorrent' && _this4.notice(_this4.translate('video-failed'), {
                        time: 3000,
                        duplicate: 'check'
                    });
                });
                this.on('ended', function () {
                    _this4.bar.set('played', 1);
                    if (!_this4.setting.loop) {
                        _this4.pause();
                    } else {
                        _this4.seek(0);
                        _this4.play();
                    }
                    if (_this4.danmaku) {
                        _this4.danmaku.danIndex = 0;
                    }
                });
                this.on('play', function () {
                    if (_this4.paused) {
                        _this4.play(true);
                    }
                });
                this.on('pause', function () {
                    if (!_this4.paused) {
                        _this4.pause(true);
                    }
                });
                this.on('timeupdate', function () {
                    if (!_this4.controller.moving) {
                        _this4.bar.set('played', _this4.video.currentTime / _this4.video.duration);
                        _this4.controller.updateChapters({
                            time: _this4.video.currentTime,
                            duration: _this4.video.duration
                        }, _this4);
                        var currentTime = utils.secondToTime(_this4.video.currentTime);
                        if (_this4.template.ptime.innerHTML !== currentTime) {
                            _this4.template.ptime.innerHTML = currentTime;
                        }
                    }
                });
                this.AllVideoEventHandlers = [];
                for (var i = 0; i < this.events.videoEvents.length; i++) {
                    this.AllVideoEventHandlers[i] = this.passVideoEvents.bind(this, this.events.videoEvents[i]);
                    video.addEventListener(this.events.videoEvents[i], this.AllVideoEventHandlers[i]);
                }
                this.volume(this.user.get('volume'), true, true);
                if (this.options.subtitle) {
                    this.subtitles = new Subtitles(this, this.template.subtitle, this.options.subtitle, this.events);
                    if (!this.user.get('subtitle')) {
                        this.subtitles.hide();
                    }
                }
            }
        },
        {
            key: 'passVideoEvents',
            value: function passVideoEvents(event) {
                this.events.trigger(event);
            }
        },
        {
            key: 'switchQuality',
            value: function switchQuality(index) {
                var _this5 = this;
                index = typeof index === 'string' ? parseInt(index) : index;
                if (this.qualityIndex === index || this.switchingQuality) {
                    return;
                } else {
                    this.prevIndex = this.qualityIndex;
                    this.qualityIndex = index;
                }
                this.switchingQuality = true;
                this.quality = this.options.video.quality[index];
                this.template.qualityButton.innerHTML = this.quality.name;
                var paused = this.video.paused;
                this.video.pause();
                var videoHTML = tplVideo({
                    current: false,
                    pic: null,
                    screenshot: this.options.screenshot,
                    preload: 'auto',
                    url: this.quality.url,
                    subtitle: this.options.subtitle
                });
                var videoEle = new DOMParser().parseFromString(videoHTML, 'text/html').body.firstChild;
                this.template.videoWrap.insertBefore(videoEle, this.template.videoWrap.getElementsByTagName('div')[0]);
                this.prevVideo = this.video;
                this.video = videoEle;
                this.initVideo(this.video, this.quality.type || this.options.video.type);
                this.seek(this.prevVideo.currentTime);
                this.notice(''.concat(this.translate('switching-quality', this.quality.name)), {
                    time: 3000,
                    mode: 'override'
                });
                this.events.trigger('quality_start', this.quality);
                this.on('canplay', function () {
                    if (_this5.prevVideo) {
                        if (_this5.video.currentTime !== _this5.prevVideo.currentTime) {
                            _this5.seek(_this5.prevVideo.currentTime);
                            return;
                        }
                        _this5.template.videoWrap.removeChild(_this5.prevVideo);
                        _this5.video.classList.add('dplayer-video-current');
                        if (!paused) {
                            _this5.video.play();
                        }
                        _this5.prevVideo = null;
                        _this5.notice(''.concat(_this5.translate('switched-quality', _this5.quality.name)));
                        _this5.switchingQuality = false;
                        _this5.events.trigger('quality_end');
                    }
                });
                this.on('error', function () {
                    if (!_this5.video.error) {
                        return;
                    }
                    if (_this5.prevVideo) {
                        _this5.template.videoWrap.removeChild(_this5.video);
                        _this5.video = _this5.prevVideo;
                        if (!paused) {
                            _this5.video.play();
                        }
                        _this5.qualityIndex = _this5.prevIndex;
                        _this5.quality = _this5.options.video.quality[_this5.qualityIndex];
                        _this5.prevVideo = null;
                        _this5.switchingQuality = false;
                    }
                });
            }
        },
        {
            key: 'notice',
            value: function notice(text, options) {
                var _this6 = this;
                options = typeof options === 'number' ? { time: options } : options || {};
                options.time = options.time || 2000;
                options.opacity = options.opacity || 0.8;
                options.mode = options.mode || 'normal';
                options.duplicate = options.duplicate || 'ignore';
                options.type = options.type || 'normal';
                if (options.warn) {
                    console.warn(text);
                }
                if (options.mode === 'override') {
                    options.time = -1;
                }
                var DontAnimate = false;
                if (options.duplicate === 'check') {
                    Array.from(this.template.noticeList.children).forEach(function (child) {
                        var childText = child.innerText;
                        if (childText === text) {
                            _this6.template.noticeList.removeChild(child);
                        }
                    });
                }
                if (options.type !== 'normal') {
                    DontAnimate = Array.from(this.template.noticeList.children).filter(function (a) {
                        return a.getAttribute('type') === options.type;
                    }).length > 0;
                }
                var notice = Template.NewNotice({
                    text: text,
                    opacity: options.opacity,
                    mode: options.mode,
                    type: options.type,
                    DontAnimate: DontAnimate
                });
                Array.from(this.template.noticeList.children).forEach(function (child) {
                    var mode = child.getAttribute('mode');
                    if (mode === 'override') {
                        _this6.template.noticeList.removeChild(child);
                    } else if (options.type !== 'normal' && child.getAttribute('type') === options.type) {
                        _this6.template.noticeList.removeChild(child);
                    }
                });
                this.template.noticeList.appendChild(notice);
                this.events.trigger('notice_show', notice);
                if (options.time > 0) {
                    setTimeout(function (e, dp) {
                        return function () {
                            e.addEventListener('animationend', function () {
                                dp.template.noticeList.removeChild(e);
                            });
                            e.classList.add('remove-notice');
                            dp.events.trigger('notice_hide');
                        };
                    }(notice, this), options.time);
                }
            }
        },
        {
            key: 'resize',
            value: function resize() {
                if (this.danmaku) {
                    this.danmaku.resize();
                }
                if (this.controller.thumbnails) {
                    this.controller.thumbnails.resize(160, this.video.videoHeight / this.video.videoWidth * 160, this.template.barWrap.offsetWidth);
                }
                this.events.trigger('resize');
            }
        },
        {
            key: 'speed',
            value: function speed(rate) {
                this.notice(this.translate('speed', ''.concat(rate * 100, '%')));
                this.video.playbackRate = rate;
            }
        },
        {
            key: 'balloon',
            value: function balloon(translate, mode) {
                if (this.options.balloon) {
                    return 'aria-label="'.concat(this.translate(translate), '" data-balloon-pos="').concat(mode, '"');
                } else {
                    return '';
                }
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                var _this7 = this;
                window.DPLAYER_INSTANCES.splice(window.DPLAYER_INSTANCES.indexOf(this), 1);
                this.pause();
                document.removeEventListener('click', this.docClickFun, true);
                this.container.removeEventListener('click', this.containerClickFun, true);
                this.video.src = '';
                this.container.innerHTML = '';
                for (var i = 0; i < this.events.videoEvents.length; i++) {
                    this.video.removeEventListener(this.events.videoEvents[i], this.AllVideoEventHandlers[i]);
                }
                this.events.trigger('destroy');
                this.controller.destroy();
                this.timer.destroy();
                this.bezel.destroy();
                this.fullScreen.destroy();
                this.hotkey.destroy();
                this.contextmenu.destroy();
                this.events.destroy();
                Object.keys(this).forEach(function (key) {
                    if (typeof _this7[key].destroy === 'function') {
                        _this7[key].destroyed || _this7[key].destroy();
                    }
                    delete _this7[key];
                });
            }
        }
    ], [{
            key: 'version',
            get: function get() {
                return DPLAYER_VERSION;
            }
        }]);
    return DPlayer;
}();
export default DPlayer;