function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
import utils from './utils';
import Thumbnails from './thumbnails';
import Icons from './icons';
import { Subject } from 'rxjs';
var cast;
var runOnce = true;
var isCasting = false;
var Controller = function () {
    function Controller(player) {
        _classCallCheck(this, Controller);
        this.player = player;
        this.autoHideTimer = 0;
        if (!utils.isMobile) {
            this.setAutoHideHandler = this.setAutoHide.bind(this);
            this.player.container.addEventListener('mousemove', this.setAutoHideHandler);
            this.player.container.addEventListener('click', this.setAutoHideHandler);
            this.player.on('play', this.setAutoHideHandler);
            this.player.on('pause', this.setAutoHideHandler);
        }
        this.initPlayButton();
        this.initThumbnails();
        this.initPlayedBar();
        this.initFullButton();
        this.initQualityButton();
        this.initScreenshotButton();
        this.initSubtitleButton();
        this.initHighlights();
        this.initAirplayButton();
        this.initChromecastButton();
        if (!utils.isMobile) {
            this.initVolumeButton();
        }
    }
    _createClass(Controller, [
        {
            key: 'initPlayButton',
            value: function initPlayButton() {
                var _this = this;
                this.player.template.playButton.addEventListener('click', function () {
                    _this.player.toggle();
                });
                this.player.template.mobilePlayButton.addEventListener('click', function () {
                    _this.player.toggle();
                });
                if (!utils.isMobile) {
                    this.player.template.videoWrap.addEventListener('click', function () {
                        _this.player.toggle();
                    });
                    this.player.template.controllerMask.addEventListener('click', function () {
                        _this.player.toggle();
                    });
                } else {
                    this.player.template.videoWrap.addEventListener('click', function () {
                        _this.toggle();
                    });
                    this.player.template.controllerMask.addEventListener('click', function () {
                        _this.toggle();
                    });
                }
            }
        },
        {
            key: 'initHighlights',
            value: function initHighlights() {
                var _this2 = this;
                this.player.on([
                    'durationchange',
                    'highlight_change'
                ], function () {
                    if (_this2.player.video.duration && _this2.player.video.duration !== 1 && _this2.player.video.duration !== Infinity) {
                        if (_this2.player.options.highlights && _this2.player.options.highlights.marker && Array.isArray(_this2.player.options.highlights.marker) && _this2.player.options.highlights.marker.length > 0) {
                            (function () {
                                var marker = _this2.player.options.highlights.marker.map(function (mark) {
                                    var corrected = mark;
                                    if (typeof mark.time !== 'number') {
                                        corrected.time = parseFloat(mark.time);
                                    }
                                    return corrected;
                                });
                                if (_this2.player.options.highlights.mode === 'auto') {
                                    if (marker[0].time === 0) {
                                        _this2.player.options.highlights.mode = 'top';
                                    } else {
                                        _this2.player.options.highlights.mode = 'normal';
                                    }
                                }
                                var inbetween = 1;
                                switch (_this2.player.options.highlights.mode) {
                                case 'normal':
                                    for (var i = 0; i < marker.length; i++) {
                                        if (typeof marker[i].text === 'undefined' || typeof marker[i].time === 'undefined') {
                                            console.warn('highlights format not correct');
                                            continue;
                                        }
                                        var p = document.createElement('div');
                                        p.classList.add('dplayer-highlight');
                                        var percent = marker[i].time / _this2.player.video.duration * 100;
                                        p.style.left = ''.concat(percent, '%');
                                        p.innerHTML = '<span class="dplayer-highlight-text">'.concat(marker[i].text, '</span>');
                                        _this2.player.template.playedBarWrap.insertBefore(p, _this2.player.template.barInfoDiv);
                                        if (marker[0].time === 0) {
                                            console.warn('The mode "normal" isn\'t meant for chapters beginning at the start, however its required for the other modes, consider using those!');
                                        } else {
                                            _this2.chapters = {
                                                marker: marker,
                                                mode: 'normal',
                                                currentChapter: -1
                                            };
                                        }
                                    }
                                    _this2.player.bar.setMode('normal');
                                    break;
                                case 'top':
                                    _this2.player.template.playedBarWrap.querySelectorAll('.dplayer-bar').forEach(function (item) {
                                        _this2.player.template.playedBarWrap.removeChild(item);
                                    });
                                    for (var _i = 0; _i < marker.length; _i++) {
                                        if (typeof marker[_i].text === 'undefined' || typeof marker[_i].time === 'undefined') {
                                            console.warn('highlights format not correct');
                                            continue;
                                        }
                                        var _p = document.createElement('div');
                                        _p.classList.add('dplayer-bar');
                                        _p.classList.add('dplayer-highlight-top');
                                        var end = (_i < marker.length - 1 ? marker[_i + 1].time / _this2.player.video.duration : 1) * 100;
                                        var start = marker[_i].time / _this2.player.video.duration * 100;
                                        _p.style.left = start === 0 ? ''.concat(start, '%') : 'calc('.concat(start, '% + ').concat(inbetween, 'px)');
                                        _p.style.right = end === 100 ? '0%' : 'calc('.concat(100 - end, '% + ').concat(inbetween, 'px)');
                                        _p.style.position = 'absolute';
                                        _p.style.width = 'unset';
                                        _p.setAttribute('data-start', start / 100);
                                        _p.setAttribute('data-end', end / 100);
                                        _p.innerHTML = '<div class="dplayer-loaded"></div><div class="dplayer-played" style="background: var(--dplayer-theme-color);"><span class="dplayer-thumb'.concat(_i === 0 ? '' : ' invisible', '" style="background: -var(--dplayer-theme-color);"></span></div>');
                                        _this2.player.template.playedBarWrap.appendChild(_p);
                                        if (marker[0].time !== 0) {
                                            console.warn('The mode "top" must have a chapter that starts at 0 / the beginning chapters beginning at the start, however its not required for the "normal" mode, consider using that mode!');
                                        } else {
                                            _this2.chapters = {
                                                marker: marker,
                                                mode: 'top',
                                                currentChapter: 0,
                                                getTextByTime: function getTextByTime(time) {
                                                    for (var _i2 = 0; _i2 < marker.length; _i2++) {
                                                        var _end = _i2 < marker.length - 1 ? marker[_i2 + 1].time : _this2.player.video.duration;
                                                        if (time < _end) {
                                                            return marker[_i2].text;
                                                        }
                                                    }
                                                }
                                            };
                                            _this2.player.template.topChapterDiv.classList.remove('hidden');
                                        }
                                    }
                                    _this2.player.bar.setMode('top');
                                    _this2.updateChapters({}, _this2.player, true);
                                    break;
                                default:
                                    console.warn('No valid mode for highlights defined: '.concat(_this2.player.options.highlights.mode));
                                }
                            }());
                        }
                    }
                    _this2.player.events.trigger('progress');
                });
                this.player.on('chapter', function (event) {
                    _this2.checkSkipState.call(_this2, event);
                });
            }
        },
        {
            key: 'checkSkipState',
            value: function checkSkipState(event) {
                var _this3 = this;
                if (!this.player.options.highlightSkip) {
                    this.player.events.trigger('cancelskip');
                    return;
                }
                switch (event.type) {
                case 'simple':
                    break;
                case 'next':
                    this.player.options.highlightSkipArray.some(function (a) {
                        if (event.current.text.match(a)) {
                            _this3.skipHighlight.call(_this3, event.next, event.current.text);
                            return true;
                        }
                        return false;
                    });
                    break;
                case 'previous':
                    if (this.player.options.hardSkipHighlights) {
                        this.player.options.highlightSkipArray.some(function (a) {
                            if (event.current.text.match(a)) {
                                _this3.skipHighlight.call(_this3, event.previous, event.current.text);
                            }
                            return false;
                        });
                    }
                    break;
                default:
                    console.warn('This shouldn\'t be Called, we only have three types of chapter events!');
                }
            }
        },
        {
            key: 'changeSkipHighlight',
            value: function changeSkipHighlight(value) {
                if (this.player.options.highlightSkip === value) {
                    return;
                }
                this.player.options.highlightSkip = value;
                var _this$chapters = this.chapters, marker = _this$chapters.marker, mode = _this$chapters.mode, currentChapter = _this$chapters.currentChapter;
                var previous = currentChapter >= 1 ? marker[currentChapter - 1] : null;
                var current = currentChapter >= 0 && currentChapter < marker.length ? marker[currentChapter] : null;
                var next = currentChapter < marker.length - 1 ? marker[currentChapter + 1] : null;
                switch (mode) {
                case 'normal':
                    break;
                case 'side':
                    break;
                case 'top':
                    this.checkSkipState({
                        type: 'next',
                        previous: previous,
                        current: current,
                        next: next
                    });
                    break;
                }
            }
        },
        {
            key: 'skipHighlight',
            value: function skipHighlight(chapter, name) {
                var _this4 = this;
                switch (this.player.options.highlightSkipMode.toString().toLowerCase()) {
                case 'smoothprompt':
                    this.showSkipPrompt.call(this, false, this.player.options.skipDelay, name, function () {
                        _this4.player.seek(chapter.time, true);
                        _this4.player.notice(_this4.player.translate('skipped_chapter', name));
                    });
                    break;
                case 'immediately':
                    this.player.seek(chapter.time, true);
                    this.player.notice(this.player.translate('skipped_chapter', name));
                    break;
                case 'smoothcancelprompt':
                    this.showSkipPrompt.call(this, true, this.player.options.skipDelay, name, function () {
                        _this4.player.seek(chapter.time, true);
                        _this4.player.notice(_this4.player.translate('skipped_chapter', name));
                    });
                    break;
                case 'always':
                    this.showSkipPrompt.call(this, false, -1, name, function () {
                        _this4.player.seek(chapter.time, true);
                        _this4.player.notice(_this4.player.translate('skipped_chapter', name));
                    });
                    break;
                default:
                    console.warn('\'options.highlightSkipMode\' not set correctly, this should not occur!');
                    break;
                }
            }
        },
        {
            key: 'showSkipPrompt',
            value: function showSkipPrompt(cancellable, timeShown, name, callback) {
                var _this5 = this;
                var prompt = this.player.template.skipWindow;
                var button = prompt.querySelector('.skip');
                var text = prompt.querySelector('.title');
                var progress = prompt.querySelector('.progress');
                if (timeShown > 0) {
                    if (cancellable) {
                        button.innerText = this.player.translate('cancel');
                        text.innerText = this.player.translate('skip_chapter', name);
                        var timeoutID = setTimeout(function () {
                            button.onclick = null;
                            prompt.style.display = 'none';
                            callback();
                        }, timeShown);
                        button.onclick = function () {
                            button.onclick = null;
                            prompt.style.display = 'none';
                            clearTimeout(timeoutID);
                        };
                        var UUIDs = this.player.once([
                            'chapter',
                            'cancelskip'
                        ], function (_, _ref) {
                            var UUID = _ref.UUID;
                            button.onclick = null;
                            prompt.style.display = 'none';
                            clearTimeout(timeoutID);
                            var remainingUUIDs = UUIDs.filter(function (u) {
                                return u !== UUID;
                            });
                            _this5.player.events.removeByUUID(remainingUUIDs);
                        }, true);
                    } else {
                        button.innerText = this.player.translate('skip');
                        text.innerText = this.player.translate('skip_chapter', name);
                        var _timeoutID = setTimeout(function () {
                            button.onclick = null;
                            prompt.style.display = 'none';
                        }, timeShown);
                        button.onclick = function () {
                            button.onclick = null;
                            prompt.style.display = 'none';
                            clearTimeout(_timeoutID);
                            callback();
                        };
                        var _UUIDs = this.player.once([
                            'chapter',
                            'cancelskip'
                        ], function (_, _ref2) {
                            var UUID = _ref2.UUID;
                            button.onclick = null;
                            prompt.style.display = 'none';
                            clearTimeout(_timeoutID);
                            var remainingUUIDs = _UUIDs.filter(function (u) {
                                return u !== UUID;
                            });
                            _this5.player.events.removeByUUID(remainingUUIDs);
                        }, true);
                    }
                    progress.style.display = 'unset';
                    progress.animate([
                        { width: '0%' },
                        { width: '100%' }
                    ], timeShown);
                } else {
                    button.innerText = this.player.translate('skip');
                    text.innerText = this.player.translate('skip_chapter', name);
                    progress.style.display = 'none';
                    button.onclick = function () {
                        button.onclick = null;
                        callback();
                    };
                    var _UUIDs2 = this.player.once([
                        'chapter',
                        'cancelskip'
                    ], function (_, _ref3) {
                        var UUID = _ref3.UUID;
                        button.onclick = null;
                        prompt.style.display = 'none';
                        var remainingUUIDs = _UUIDs2.filter(function (u) {
                            return u !== UUID;
                        });
                        _this5.player.events.removeByUUID(remainingUUIDs);
                    }, true);
                }
                prompt.style.display = 'flex';
            }
        },
        {
            key: 'initThumbnails',
            value: function initThumbnails() {
                var _this6 = this;
                if (this.player.options.video.thumbnails) {
                    this.thumbnails = new Thumbnails({
                        container: this.player.template.barPreview,
                        barWidth: this.player.template.barWrap.offsetWidth,
                        url: this.player.options.video.thumbnails,
                        events: this.player.events
                    });
                    this.player.on('loadedmetadata', function () {
                        _this6.thumbnails.resize(_this6.player.video.videoHeight / _this6.player.video.videoWidth, _this6.player.template.barWrap.offsetWidth);
                    });
                }
            }
        },
        {
            key: 'initPlayedBar',
            value: function initPlayedBar() {
                var _this7 = this;
                var thumbMove = function thumbMove(e) {
                    _this7.moving = true;
                    var x = e.clientX || e.changedTouches[0].clientX;
                    if (!x) {
                        return;
                    }
                    var percentage = (x - utils.getBoundingClientRectViewLeft(_this7.player.template.playedBarWrap)) / _this7.player.template.playedBarWrap.clientWidth;
                    if (_this7.chapters && _this7.chapters.mode === 'top') {
                    }
                    percentage = _this7.clamp(0, percentage, 1);
                    _this7.player.bar.set('played', percentage);
                    _this7.player.template.ptime.innerHTML = utils.secondToTime(percentage * _this7.player.video.duration);
                };
                var thumbUp = function thumbUp(e) {
                    document.removeEventListener(utils.nameMap.dragEnd, thumbUp);
                    document.removeEventListener(utils.nameMap.dragMove, thumbMove);
                    _this7.moving = false;
                    var x = e.clientX || e.changedTouches[0].clientX;
                    if (!x) {
                        return;
                    }
                    var percentage = (x - utils.getBoundingClientRectViewLeft(_this7.player.template.playedBarWrap)) / _this7.player.template.playedBarWrap.clientWidth;
                    if (_this7.chapters && _this7.chapters.mode === 'top') {
                    }
                    percentage = _this7.clamp(0, percentage, 1);
                    _this7.player.bar.set('played', percentage);
                    _this7.updateChapters({ percentage: percentage }, _this7.player);
                    _this7.player.seek(percentage * _this7.player.video.duration);
                    _this7.player.timer.enable('progress');
                };
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragStart, function () {
                    _this7.player.timer.disable('progress');
                    document.addEventListener(utils.nameMap.dragMove, thumbMove);
                    document.addEventListener(utils.nameMap.dragEnd, thumbUp);
                });
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragMove, function (e) {
                    if (_this7.player.video.duration) {
                        var px = _this7.player.template.playedBarWrap.getBoundingClientRect().left;
                        var tx = (e.clientX || e.changedTouches[0].clientX) - px;
                        if (tx < 0 || tx > _this7.player.template.playedBarWrap.offsetWidth) {
                            return;
                        }
                        var time = _this7.player.video.duration * (tx / _this7.player.template.playedBarWrap.offsetWidth);
                        if (utils.isMobile) {
                            _this7.thumbnails && _this7.thumbnails.show();
                        }
                        _this7.thumbnails && _this7.thumbnails.move(tx);
                        var ElemWidth = (parseFloat(window.getComputedStyle(_this7.player.template.barInfoDiv).width.replace('px', '')) + 14) / 2;
                        var percentage = _this7.clamp(-22, tx - ElemWidth, _this7.player.template.playedBarWrap.offsetWidth + 22 - 2 * ElemWidth);
                        _this7.player.template.barInfoDiv.style.left = ''.concat(percentage, 'px');
                        _this7.player.template.barInfoDiv.classList.remove('hidden');
                        _this7.player.template.playedBarTime.innerText = utils.secondToTime(time);
                        if (_this7.chapters && _this7.chapters.mode === 'top') {
                            _this7.player.template.topChapterDiv.innerText = _this7.chapters.getTextByTime(time);
                        }
                    }
                });
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragEnd, function () {
                    if (utils.isMobile) {
                        _this7.thumbnails && _this7.thumbnails.hide();
                    }
                });
                if (!utils.isMobile) {
                    this.player.template.playedBarWrap.addEventListener('mouseenter', function () {
                        if (_this7.player.video.duration) {
                            _this7.thumbnails && _this7.thumbnails.show();
                            _this7.player.template.barInfoDiv.classList.remove('hidden');
                        }
                    });
                    this.player.template.playedBarWrap.addEventListener('mouseleave', function () {
                        if (_this7.player.video.duration) {
                            _this7.thumbnails && _this7.thumbnails.hide();
                            _this7.player.template.barInfoDiv.classList.add('hidden');
                        }
                    });
                }
            }
        },
        {
            key: 'initFullButton',
            value: function initFullButton() {
                var _this8 = this;
                switch (this.player.options.fullScreenPolicy.toString().toLowerCase()) {
                case 'onlynormal':
                    this.player.template.webFullButton.classList.add('only-normal-mode');
                    break;
                case 'onlyweb':
                    this.player.template.browserFullButton.classList.add('only-web-mode');
                    break;
                case 'both':
                    this.player.template.browserFullButton.classList.add('both-mode');
                    this.player.template.webFullButton.classList.add('both-mode');
                    break;
                default:
                    console.warn('\'options.fullScreenPolicy\' not set correctly, this should not occur!');
                    break;
                }
                this.player.template.browserFullButton.addEventListener('click', function () {
                    _this8.player.fullScreen.toggle('browser');
                });
                this.player.template.webFullButton.addEventListener('click', function () {
                    _this8.player.fullScreen.toggle('web');
                });
            }
        },
        {
            key: 'initVolumeButton',
            value: function initVolumeButton() {
                var _this9 = this;
                var vWidth = 35;
                var volumeMove = function volumeMove(event) {
                    var e = event || window.event;
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this9.player.template.volumeBarWrap) - 5.5) / vWidth;
                    _this9.player.volume(percentage);
                };
                var volumeUp = function volumeUp() {
                    document.removeEventListener(utils.nameMap.dragEnd, volumeUp);
                    document.removeEventListener(utils.nameMap.dragMove, volumeMove);
                    _this9.player.template.volumeButton.classList.remove('dplayer-volume-active');
                };
                this.player.template.volumeBarWrapWrap.addEventListener('click', function (event) {
                    var e = event || window.event;
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this9.player.template.volumeBarWrap) - 5.5) / vWidth;
                    _this9.player.volume(percentage);
                });
                this.player.template.volumeBarWrapWrap.addEventListener(utils.nameMap.dragStart, function () {
                    document.addEventListener(utils.nameMap.dragMove, volumeMove);
                    document.addEventListener(utils.nameMap.dragEnd, volumeUp);
                    _this9.player.template.volumeButton.classList.add('dplayer-volume-active');
                });
                this.player.template.volumeButtonIcon.addEventListener('click', function () {
                    _this9.muteChanger.call(_this9);
                });
            }
        },
        {
            key: 'initQualityButton',
            value: function initQualityButton() {
                var _this10 = this;
                if (this.player.options.video.quality) {
                    this.player.template.qualityList.addEventListener('click', function (e) {
                        if (e.target.classList.contains('dplayer-quality-item')) {
                            _this10.player.switchQuality(e.target.dataset.index);
                        }
                    });
                }
            }
        },
        {
            key: 'initScreenshotButton',
            value: function initScreenshotButton() {
                var _this11 = this;
                if (this.player.options.screenshot) {
                    this.player.template.cameraButton.addEventListener('click', function () {
                        _this11.SaveScreenshot.call(_this11);
                    });
                }
            }
        },
        {
            key: 'initAirplayButton',
            value: function initAirplayButton() {
                if (this.player.options.airplay) {
                    if (window.WebKitPlaybackTargetAvailabilityEvent) {
                        this.player.video.addEventListener('webkitplaybacktargetavailabilitychanged', function (event) {
                            switch (event.availability) {
                            case 'available':
                                this.template.airplayButton.disable = false;
                                break;
                            default:
                                this.template.airplayButton.disable = true;
                            }
                            this.template.airplayButton.addEventListener('click', function () {
                                this.video.webkitShowPlaybackTargetPicker();
                            }.bind(this));
                        }.bind(this.player));
                    } else {
                        this.player.template.airplayButton.style.display = 'none';
                    }
                }
            }
        },
        {
            key: 'initChromecast',
            value: function initChromecast() {
                var script = window.document.createElement('script');
                script.setAttribute('type', 'text/javascript');
                script.setAttribute('src', 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1');
                window.document.body.appendChild(script);
                window.__onGCastApiAvailable = function (isAvailable) {
                    if (isAvailable) {
                        cast = window.chrome.cast;
                        var sessionRequest = new cast.SessionRequest(cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID);
                        var apiConfig = new cast.ApiConfig(sessionRequest, function () {
                        }, function (status) {
                            if (status === cast.ReceiverAvailability.AVAILABLE) {
                                console.info('chromecast: ', status);
                            }
                        });
                        cast.initialize(apiConfig, function () {
                        });
                    }
                };
            }
        },
        {
            key: 'initChromecastButton',
            value: function initChromecastButton() {
                var _this12 = this;
                if (this.player.options.chromecast) {
                    if (runOnce) {
                        runOnce = false;
                        this.initChromecast();
                    }
                    var discoverDevices = function discoverDevices() {
                        var subj = new Subject();
                        cast.requestSession(function (s) {
                            _this12.session = s;
                            subj.next('CONNECTED');
                            launchMedia(_this12.player.options.video.url);
                        }, function (err) {
                            if (err.code === 'cancel') {
                                _this12.session = undefined;
                                subj.next('CANCEL');
                            } else {
                                console.error('Error selecting a cast device', err);
                            }
                        });
                        return subj;
                    };
                    var launchMedia = function launchMedia(media) {
                        var mediaInfo = new cast.media.MediaInfo(media);
                        var request = new cast.media.LoadRequest(mediaInfo);
                        if (!_this12.session) {
                            window.open(media);
                            return false;
                        }
                        _this12.session.loadMedia(request, onMediaDiscovered.bind(_this12, 'loadMedia'), onMediaError).play();
                        return true;
                    };
                    var onMediaDiscovered = function onMediaDiscovered(how, media) {
                        _this12.currentMedia = media;
                    };
                    var onMediaError = function onMediaError(err) {
                        console.error('Error launching media', err);
                    };
                    this.player.template.chromecastButton.addEventListener('click', function () {
                        if (isCasting) {
                            isCasting = false;
                            _this12.currentMedia.stop();
                            _this12.session.stop();
                            _this12.initChromecast();
                        } else {
                            isCasting = true;
                            discoverDevices();
                        }
                    });
                }
            }
        },
        {
            key: 'initSubtitleButton',
            value: function initSubtitleButton() {
                var _this13 = this;
                if (this.player.options.subtitle) {
                    this.player.events.on('subtitle_show', function () {
                        _this13.player.template.subtitleButton.dataset.balloon = _this13.player.translate('hide-subs');
                        _this13.player.template.subtitleButtonInner.style.opacity = '';
                        _this13.player.user.set('subtitle', 1);
                    });
                    this.player.events.on('subtitle_hide', function () {
                        _this13.player.template.subtitleButton.dataset.balloon = _this13.player.translate('show-subs');
                        _this13.player.template.subtitleButtonInner.style.opacity = '0.4';
                        _this13.player.user.set('subtitle', 0);
                    });
                    this.player.template.subtitleButton.addEventListener('click', function () {
                        _this13.player.subtitles.toggle();
                    });
                }
            }
        },
        {
            key: 'setAutoHide',
            value: function setAutoHide() {
                var _this14 = this;
                this.show();
                clearTimeout(this.autoHideTimer);
                this.autoHideTimer = setTimeout(function () {
                    if (_this14.player.video.played.length && !_this14.player.paused && !_this14.disableAutoHide) {
                        _this14.hide();
                    }
                }, 3000);
            }
        },
        {
            key: 'show',
            value: function show() {
                this.player.container.classList.remove('dplayer-hide-controller');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.container.classList.add('dplayer-hide-controller');
                this.player.setting.hide();
                this.player.comment && this.player.comment.hide();
                if (this.player.subtitles) {
                    this.player.subtitles.hideModal();
                }
            }
        },
        {
            key: 'isShow',
            value: function isShow() {
                return !this.player.container.classList.contains('dplayer-hide-controller');
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.isShow()) {
                    this.hide();
                } else {
                    this.show();
                }
            }
        },
        {
            key: 'updateChapters',
            value: function updateChapters() {
                var object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
                var player = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.player;
                var start = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
                var percentage = object.percentage, time = object.time, duration = object.duration;
                if (percentage) {
                    duration = duration || player.video.duration;
                    time = time || percentage * duration;
                } else if (time) {
                    duration = duration || player.video.duration;
                    percentage = percentage || time / duration;
                } else if (duration) {
                    time = time || player.video.currentTime;
                    percentage = percentage || time / duration;
                } else {
                    duration = duration || player.video.duration;
                    time = time || player.video.currentTime;
                    percentage = percentage || time / duration;
                }
                if (this.chapters) {
                    var _this$chapters2 = this.chapters, marker = _this$chapters2.marker, mode = _this$chapters2.mode, currentChapter = _this$chapters2.currentChapter;
                    var previous = currentChapter >= 1 ? marker[currentChapter - 1] : null;
                    var next = currentChapter < marker.length - 1 ? marker[currentChapter + 1] : null;
                    var current = currentChapter >= 0 && currentChapter < marker.length ? marker[currentChapter] : null;
                    var previous_condition = current && current.time > time;
                    var next_condition = next && next.time <= time;
                    switch (mode) {
                    case 'normal':
                        if (previous_condition) {
                            this.chapters.currentChapter--;
                            this.player.events.trigger('chapter', {
                                type: 'simple',
                                direction: 'previous',
                                surpassed: current
                            });
                        } else if (next_condition) {
                            this.chapters.currentChapter++;
                            this.player.events.trigger('chapter', {
                                type: 'simple',
                                direction: 'next',
                                surpassed: next
                            });
                        }
                        break;
                    case 'side':
                        break;
                    case 'top':
                        if (previous_condition) {
                            this.chapters.currentChapter--;
                            this.player.events.trigger('chapter', {
                                type: 'previous',
                                previous: currentChapter >= 2 ? marker[currentChapter - 2] : null,
                                current: previous,
                                next: current
                            });
                        } else if (next_condition) {
                            this.chapters.currentChapter++;
                            this.player.events.trigger('chapter', {
                                type: 'next',
                                previous: current,
                                current: next,
                                next: currentChapter < marker.length - 2 ? marker[currentChapter + 2] : null
                            });
                        } else if (start === true) {
                            this.player.events.trigger('chapter', {
                                type: 'next',
                                previous: previous,
                                current: current,
                                next: next
                            });
                        }
                        break;
                    }
                }
            }
        },
        {
            key: 'goToChapter',
            value: function goToChapter(number) {
                if (!this.chapters) {
                    return;
                }
                var _this$chapters3 = this.chapters, marker = _this$chapters3.marker, mode = _this$chapters3.mode, currentChapter = _this$chapters3.currentChapter;
                var actualTime = this.player.video.currentTime;
                var goToChapter = currentChapter + number;
                var togo;
                var Threshold = 1.5;
                switch (mode) {
                case 'top':
                    togo = goToChapter >= 0 && goToChapter < marker.length ? marker[goToChapter] : goToChapter === marker.length ? { time: this.player.video.duration } : null;
                    if (number === 0 && Math.abs(togo.time - actualTime) <= Threshold) {
                        goToChapter--;
                        togo = goToChapter >= 0 && goToChapter < marker.length ? marker[goToChapter] : goToChapter === marker.length ? { time: this.player.video.duration } : null;
                    }
                    if (togo !== null) {
                        this.player.seek(togo.time);
                    }
                    break;
                case 'side':
                    break;
                case 'normal':
                    togo = goToChapter >= 0 && goToChapter < marker.length ? marker[goToChapter] : goToChapter === marker.length ? { time: this.player.video.duration } : goToChapter === -1 ? { time: 0 } : null;
                    if (number === 0 && Math.abs(togo.time - actualTime) <= Threshold) {
                        goToChapter--;
                        togo = goToChapter >= 0 && goToChapter < marker.length ? marker[goToChapter] : goToChapter === marker.length ? { time: this.player.video.duration } : goToChapter === -1 ? { time: 0 } : null;
                    }
                    if (togo !== null) {
                        this.player.seek(togo.time);
                    }
                    break;
                }
            }
        },
        {
            key: 'muteChanger',
            value: function muteChanger() {
                if (this.player.video.muted) {
                    this.player.video.muted = false;
                    this.player.switchVolumeIcon();
                    this.player.bar.set('volume', this.player.volume());
                } else {
                    this.player.video.muted = true;
                    this.player.template.volumeIcon.innerHTML = Icons.volumeOff;
                    this.player.bar.set('volume', 0);
                }
            }
        },
        {
            key: 'SaveScreenshot',
            value: function SaveScreenshot() {
                var _this15 = this;
                this.player.template.cameraButton.classList.add('taking-screenshot');
                var canvas = document.createElement('canvas');
                canvas.width = this.player.video.videoWidth;
                canvas.height = this.player.video.videoHeight;
                canvas.getContext('2d').drawImage(this.player.video, 0, 0, canvas.width, canvas.height);
                var dataURL;
                canvas.toBlob(function (blob) {
                    if (blob) {
                        dataURL = URL.createObjectURL(blob);
                        var link = document.createElement('a');
                        link.href = dataURL;
                        var temp = new URL(_this15.player.video.currentSrc).pathname;
                        var name = decodeURI(temp.substring(temp.lastIndexOf('/') + 1));
                        var time = utils.secondToTime(_this15.player.video.currentTime, '_');
                        var downloadName = ''.concat(name, '_Screenshot_').concat(time, '.png');
                        link.download = downloadName;
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        link.click();
                        _this15.player.events.trigger('screenshot', dataURL);
                        _this15.player.notice(_this15.player.translate('saved-screenshot', downloadName));
                        document.body.removeChild(link);
                        URL.revokeObjectURL(dataURL);
                        _this15.player.container.click();
                    } else {
                        console.info('Screenshot Error, video not loaded yet!');
                    }
                    _this15.player.template.cameraButton.classList.remove('taking-screenshot');
                });
            }
        },
        {
            key: 'clamp',
            value: function clamp(min, number, max) {
                var z = Math.max(Math.min(number, max), min);
                return z;
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                if (!utils.isMobile) {
                    this.player.container.removeEventListener('mousemove', this.setAutoHideHandler);
                    this.player.container.removeEventListener('click', this.setAutoHideHandler);
                }
                clearTimeout(this.autoHideTimer);
                this.destroyed = true;
            }
        }
    ]);
    return Controller;
}();
export default Controller;