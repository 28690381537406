function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
    throw new TypeError('Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.');
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o)
        return;
    if (typeof o === 'string')
        return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === 'Object' && o.constructor)
        n = o.constructor.name;
    if (n === 'Map' || n === 'Set')
        return Array.from(o);
    if (n === 'Arguments' || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
        return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length)
        len = arr.length;
    for (var i = 0, arr2 = new Array(len); i < len; i++) {
        arr2[i] = arr[i];
    }
    return arr2;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== 'undefined' && arr[Symbol.iterator] || arr['@@iterator'];
    if (_i == null)
        return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) {
            _arr.push(_s.value);
            if (i && _arr.length === i)
                break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally {
        try {
            if (!_n && _i['return'] != null)
                _i['return']();
        } finally {
            if (_d)
                throw _e;
        }
    }
    return _arr;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr))
        return arr;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        enumerableOnly && (symbols = symbols.filter(function (sym) {
            return Object.getOwnPropertyDescriptor(object, sym).enumerable;
        })), keys.push.apply(keys, symbols);
    }
    return keys;
}
function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = null != arguments[i] ? arguments[i] : {};
        i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
            _defineProperty(target, key, source[key]);
        }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
import utils from './utils.js';
var keys = {
    32: 'togglePlayer',
    37: 'left',
    39: 'right',
    38: 'up',
    40: 'down',
    27: 'cancelbothfullscreen',
    70: 'toggleFullscreen',
    87: 'toggleWebFullscreen',
    77: 'mute',
    83: 'screenshot',
    68: 'nextChapter',
    65: 'previousChapter',
    76: 'changeLoop',
    86: 'speedUp',
    67: 'speedDown',
    78: 'speedNormal'
};
var advancedKeys = {
    ' ': 'togglePlayer',
    ArrowLeft: 'left',
    ArrowRight: 'right',
    ArrowUp: 'up',
    ArrowDown: 'down',
    Escape: 'cancelFullscreen',
    f: 'toggleFullscreen',
    w: 'toggleWebFullscreen',
    MediaPlayPause: 'togglePlayer',
    MediaStop: 'stopPlayer',
    AudioVolumeMute: 'mute',
    m: 'mute',
    s: 'screenshot',
    MediaTrackPrevious: 'nextChapter',
    MediaTrackNext: 'previousChapter',
    d: 'nextChapter',
    a: 'previousChapter',
    l: 'changeLoop',
    v: 'speedUp',
    c: 'speedDown',
    n: 'speedNormal'
};
var HotKey = function () {
    function HotKey(player) {
        _classCallCheck(this, HotKey);
        this.player = player;
        this.doHotKeyHandler = this.doHotKey.bind(this);
        if (this.player.options.hotkey) {
            document.addEventListener('keydown', this.doHotKeyHandler);
            this.enabledKeys = {
                keys: utils.deepCopyObject(keys),
                advancedKeys: utils.deepCopyObject(advancedKeys)
            };
            this.disabledKeys = {
                keys: {},
                advancedKeys: {}
            };
            switch (this.player.options.fullScreenPolicy.toString().toLowerCase()) {
            case 'onlynormal':
                this.disabledKeys.keys = _objectSpread(_objectSpread({}, this.disabledKeys.keys), {}, { 87: keys[87] });
                delete this.enabledKeys.keys[87];
                this.disabledKeys.advancedKeys = _objectSpread(_objectSpread({}, this.disabledKeys.advancedKeys), {}, { w: advancedKeys.w });
                delete this.enabledKeys.advancedKeys.w;
                break;
            case 'onlyweb':
                this.disabledKeys.keys = _objectSpread(_objectSpread({}, this.disabledKeys.keys), {}, { 70: keys[70] });
                delete this.enabledKeys.keys[70];
                this.disabledKeys.advancedKeys = _objectSpread(_objectSpread({}, this.disabledKeys.advancedKeys), {}, { f: advancedKeys.f });
                delete this.enabledKeys.advancedKeys.f;
                break;
            case 'both':
                break;
            default:
                console.warn('\'options.fullScreenPolicy\' not set correctly, this should not occur!');
                break;
            }
        }
    }
    _createClass(HotKey, [
        {
            key: 'doHotKey',
            value: function doHotKey(e) {
                if (this.player.focus) {
                    var tag = document.activeElement.tagName.toUpperCase();
                    var editable = document.activeElement.getAttribute('contenteditable');
                    if (tag !== 'INPUT' && tag !== 'TEXTAREA' && editable !== '' && editable !== 'true') {
                        var event = e || window.event;
                        var percentage;
                        this.player.hotkeyPanel.parse(event.keyCode);
                        switch (this.key(event)) {
                        case 'togglePlayer':
                            event.preventDefault();
                            this.player.toggle();
                            break;
                        case 'stopPlayer':
                            event.preventDefault();
                            this.player.pause();
                            break;
                        case 'left':
                            event.preventDefault();
                            if (this.player.options.live) {
                                break;
                            }
                            this.player.seek(this.player.video.currentTime - 5);
                            this.player.controller.setAutoHide();
                            break;
                        case 'right':
                            event.preventDefault();
                            if (this.player.options.live) {
                                break;
                            }
                            this.player.seek(this.player.video.currentTime + 5);
                            this.player.controller.setAutoHide();
                            break;
                        case 'up':
                            event.preventDefault();
                            percentage = this.player.volume() + 0.1;
                            this.player.volume(percentage);
                            break;
                        case 'down':
                            event.preventDefault();
                            percentage = this.player.volume() - 0.1;
                            this.player.volume(percentage);
                            break;
                        case 'toggleFullscreen':
                            event.preventDefault();
                            this.player.fullScreen.toggle('browser');
                            break;
                        case 'cancelbothfullscreen':
                            event.preventDefault();
                            if (this.player.fullScreen.isFullScreen('web')) {
                                this.player.fullScreen.cancel('web');
                            }
                            event.preventDefault();
                            if (this.player.fullScreen.isFullScreen('browser')) {
                                this.player.fullScreen.cancel('browser');
                            }
                            break;
                        case 'toggleWebFullscreen':
                            event.preventDefault();
                            this.player.fullScreen.toggle('web');
                            break;
                        case 'mute':
                            event.preventDefault();
                            this.player.controller.muteChanger();
                            break;
                        case 'screenshot':
                            event.preventDefault();
                            if (this.player.options.screenshot) {
                                this.player.controller.SaveScreenshot();
                            }
                            break;
                        case 'changeLoop':
                            event.preventDefault();
                            this.player.setting.toggleLoop();
                            break;
                        case 'speedUp':
                            event.preventDefault();
                            this.player.setting.EditSpeed(1);
                            break;
                        case 'speedDown':
                            event.preventDefault();
                            this.player.setting.EditSpeed(-1);
                            break;
                        case 'speedNormal':
                            event.preventDefault();
                            this.player.speed(1);
                            break;
                        case 'previousChapter':
                            event.preventDefault();
                            this.player.controller.goToChapter(0);
                            break;
                        case 'nextChapter':
                            event.preventDefault();
                            this.player.controller.goToChapter(1);
                            break;
                        }
                    }
                }
            }
        },
        {
            key: 'keyOLD',
            value: function keyOLD(event) {
                switch (event.keyCode) {
                case 32:
                    return 'togglePlayer';
                case 37:
                    return 'left';
                case 39:
                    return 'right';
                case 38:
                    return 'up';
                case 40:
                    return 'down';
                case 27:
                    return 'cancelbothfullscreen';
                case 70:
                    return 'toggleFullscreen';
                case 77:
                    return 'mute';
                case 83:
                    return 'screenshot';
                case 68:
                    return 'nextChapter';
                case 65:
                    return 'previousChapter';
                case 76:
                    return 'changeLoop';
                case 86:
                    return 'speedUp';
                case 67:
                    return 'speedDown';
                case 78:
                    return 'speedNormal';
                }
                if (this.player.options.advancedHotkeys) {
                    switch (event.key) {
                    case ' ':
                        return 'togglePlayer';
                    case 'ArrowLeft':
                        return 'left';
                    case 'ArrowRight':
                        return 'right';
                    case 'ArrowUp':
                        return 'up';
                    case 'ArrowDown':
                        return 'down';
                    case 'Escape':
                        return 'cancelbothfullscreen';
                    case 'f':
                        return 'toggleFullscreen';
                    case 'MediaPlayPause':
                        return 'togglePlayer';
                    case 'MediaStop':
                        return 'stopPlayer';
                    case 'AudioVolumeMute':
                        return 'mute';
                    case 'm':
                        return 'mute';
                    case 's':
                        return 'screenshot';
                    case 'MediaTrackPrevious':
                        return 'nextChapter';
                    case 'MediaTrackNext':
                        return 'previousChapter';
                    case 'd':
                        return 'nextChapter';
                    case 'a':
                        return 'previousChapter';
                    case 'l':
                        return 'changeLoop';
                    case 'v':
                        return 'speedUp';
                    case 'c':
                        return 'speedDown';
                    case 'n':
                        return 'speedNormal';
                    }
                }
            }
        },
        {
            key: 'key',
            value: function key(event) {
                if (this.enabledKeys.keys[event.keyCode]) {
                    return this.enabledKeys.keys[event.keyCode];
                }
                if (this.player.options.advancedHotkeys) {
                    if (this.enabledKeys.advancedKeys[event.keyCode]) {
                        return this.enabledKeys.advancedKeys[event.keyCode];
                    }
                }
            }
        },
        {
            key: 'keysOld',
            value: function keysOld() {
                var enabled = [];
                for (var i = 32; i <= 90; i++) {
                    var rep_key = this.key({ keyCode: i });
                    if (rep_key) {
                        var key = String.fromCharCode(i).toLowerCase().replace(' ', '{space}').replace('%', '{arrowleft}').replace('&', '{arrowup}').replace('\'', '{arrowright}').replace('(', '{arrowdown}');
                        enabled.push({
                            key: key,
                            tooltip: rep_key,
                            keyCode: i
                        });
                    }
                }
                return enabled;
            }
        },
        {
            key: 'keys',
            value: function keys() {
                var enabled = Object.entries(this.enabledKeys.keys).map(function (a) {
                    var _a = _slicedToArray(a, 2), index = _a[0], name = _a[1];
                    var key = String.fromCharCode(index).toLowerCase().replace(' ', '{space}').replace('%', '{arrowleft}').replace('&', '{arrowup}').replace('\'', '{arrowright}').replace('(', '{arrowdown}');
                    return {
                        key: key,
                        tooltip: name,
                        keyCode: index
                    };
                });
                var disabled = Object.entries(this.disabledKeys.keys).map(function (a) {
                    var _a2 = _slicedToArray(a, 2), index = _a2[0], name = _a2[1];
                    var key = String.fromCharCode(index).toLowerCase().replace(' ', '{space}').replace('%', '{arrowleft}').replace('&', '{arrowup}').replace('\'', '{arrowright}').replace('(', '{arrowdown}');
                    return {
                        key: key,
                        tooltip: name,
                        keyCode: index
                    };
                });
                return {
                    enabled: enabled,
                    disabled: disabled,
                    all: enabled.concat(disabled)
                };
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                if (this.player.options.hotkey) {
                    document.removeEventListener('keydown', this.doHotKeyHandler);
                }
                document.removeEventListener('keydown', this.cancelFullScreenHandler);
                this.destroyed = true;
            }
        }
    ]);
    return HotKey;
}();
export default HotKey;