{{ set enableSubtitle = subtitle && subtitle.type === 'webvtt' }}
<video
    class="dplayer-video {{ if current }}dplayer-video-current{{ /if }}"
    webkit-playsinline
    {{ if airplay }} x-webkit-airplay="allow" {{ /if }}
    playsinline
    {{ if pic }}poster="{{ pic }}"{{ /if }}
    {{ if screenshot || enableSubtitle }}crossorigin="anonymous"{{ /if }}
    {{ if preload }}preload="{{ preload }}"{{ /if }}
    {{ if airplay }}
    nosrc
    {{ else if url }}
    src="{{ url }}"
    {{ /if }}
    >
    {{ if airplay }}
    <source src="{{ url }}">
    {{ /if}}
    {{ if enableSubtitle }}
    <track class="dplayer-subtrack" kind="metadata" default src="{{  subtitle.url.length <= 2 ? subtitle.url[0].subtitle : subtitle.url[subtitle.index].subtitle }}"></track>
    {{ /if }}
</video>