function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
var Bar = function () {
    function Bar(template, player, mode) {
        _classCallCheck(this, Bar);
        this.elements = {};
        this.elements.volume = template.volumeBar;
        this.elements.played = template.playedBar;
        this.elements.loaded = template.loadedBar;
        this.elements.danmaku = template.danmakuOpacityBar;
        this.mode = mode;
        this.player = player;
        this.previousRanges = [];
        this.previousMode = mode;
        this.RangesChanged = false;
    }
    _createClass(Bar, [
        {
            key: 'set',
            value: function set(type, Obj) {
                var _this = this;
                var percentage, ranges, force;
                if (typeof Obj === 'number') {
                    percentage = Obj;
                    force = Obj.force ? true : false;
                    ranges = [];
                    for (var i = 0; i < this.player.video.buffered.length; i++) {
                        var start = this.player.video.buffered.start(i);
                        var end = this.player.video.buffered.end(i);
                        ranges.push({
                            start: start,
                            end: end
                        });
                    }
                } else {
                    percentage = Obj.percentage;
                    ranges = Obj.ranges;
                    force = Obj.force ? true : false;
                }
                this.RangesChanged = !(JSON.stringify(ranges) === JSON.stringify(this.previousRanges)) || this.previousMode !== this.mode || force;
                if (!(JSON.stringify(ranges) === JSON.stringify(this.previousRanges))) {
                    this.player.events.trigger('ranges_change', ranges);
                }
                var previousRanges = this.previousRanges;
                this.previousRanges = ranges;
                this.previousMode = this.mode;
                percentage = Math.max(percentage, 0);
                percentage = Math.min(percentage, 1);
                var LPercentage = this.player.video.buffered.length > 0 ? this.player.video.buffered.end(this.player.video.buffered.length - 1) / this.player.video.duration : percentage;
                switch (this.mode || 'nomode') {
                case 'normal':
                    this.elements[type].style.width = ''.concat(percentage * 100, '%');
                    if (this.RangesChanged && ranges && ranges.length > 0) {
                        type = 'loaded';
                        this.elements[type].style.width = ''.concat(LPercentage * 100, '%');
                        if (ranges.length < previousRanges.length) {
                            Array.from(this.elements[type].children).forEach(function (a) {
                                return _this.elements[type].removeChild(a);
                            });
                        }
                        ranges.forEach(function (range, index) {
                            var start = range.start;
                            var end = range.end;
                            var exists = Array.from(_this.elements[type].children).filter(function (a) {
                                return a.getAttribute('data-index') === index.toString();
                            });
                            var Loaded_dur = _this.player.video.duration * LPercentage;
                            var p;
                            if (exists.length > 0) {
                                p = exists[0];
                                p.style.left = ''.concat(start / Loaded_dur * 100, '%');
                                p.style.width = ''.concat((end - start) / Loaded_dur * 100, '%');
                                p.setAttribute('data-start', start);
                                p.setAttribute('data-end', end);
                            } else {
                                p = document.createElement('div');
                                p.classList.add('dplayer-loaded-strips');
                                p.setAttribute('data-index', index);
                                p.style.left = ''.concat(start / Loaded_dur * 100, '%');
                                p.style.width = ''.concat((end - start) / Loaded_dur * 100, '%');
                                p.setAttribute('data-start', start);
                                p.setAttribute('data-end', end);
                                _this.elements[type].appendChild(p);
                            }
                        });
                    }
                    break;
                case 'side':
                    break;
                case 'top':
                    if (type === 'played') {
                        this.elements.played = this.player.container.querySelectorAll('.dplayer-played');
                        this.elements.played.forEach(function (played) {
                            var parent = played.parentElement;
                            var start = parseFloat(parent.getAttribute('data-start'));
                            var end = parseFloat(parent.getAttribute('data-end'));
                            if (percentage >= end) {
                                played.style.width = '100%';
                                played.children[0].classList.add('invisible');
                            } else if (percentage < start) {
                                played.style.width = '0%';
                                played.children[0].classList.add('invisible');
                            } else if (percentage < end && percentage >= start) {
                                var all = end - start;
                                var inHere = percentage - start;
                                played.style.width = ''.concat(inHere / all * 100, '%');
                                played.children[0].classList.remove('invisible');
                            }
                        });
                    } else if (type === 'loaded') {
                        this.elements.loaded = this.player.container.querySelectorAll('.dplayer-loaded');
                        this.elements.loaded.forEach(function (loaded) {
                            var parent = loaded.parentElement;
                            var start = parseFloat(parent.getAttribute('data-start'));
                            var end = parseFloat(parent.getAttribute('data-end'));
                            if (percentage >= end) {
                                loaded.style.width = '100%';
                            } else if (percentage < start) {
                                loaded.style.width = '0%';
                            } else if (percentage < end && percentage >= start) {
                                var all = end - start;
                                var inHere = percentage - start;
                                loaded.style.width = ''.concat(inHere / all * 100, '%');
                            }
                        });
                    } else {
                        this.elements[type].style.width = percentage * 100 + '%';
                    }
                    if (this.RangesChanged && ranges && ranges.length > 0) {
                        this.elements.loaded = this.player.container.querySelectorAll('.dplayer-loaded');
                        this.elements.loaded.forEach(function (loaded) {
                            if (ranges.length < previousRanges.length) {
                                Array.from(loaded.children).forEach(function (a) {
                                    return loaded.removeChild(a);
                                });
                            }
                            var parent = loaded.parentElement;
                            var start = parseFloat(parent.getAttribute('data-start'));
                            var end = parseFloat(parent.getAttribute('data-end'));
                            var abs_start = start * _this.player.video.duration;
                            var abs_end = end * _this.player.video.duration;
                            var progress = 0;
                            if (LPercentage >= end) {
                                loaded.style.width = '100%';
                                progress = 1;
                            } else if (LPercentage < start) {
                                loaded.style.width = '0%';
                                progress = 0;
                            } else if (LPercentage < end && LPercentage >= start) {
                                var all = end - start;
                                var inHere = LPercentage - start;
                                loaded.style.width = ''.concat(inHere / all * 100, '%');
                                progress = inHere / all;
                            }
                            var filtered_ranges = ranges.filter(function (range) {
                                return range.start <= abs_end && range.start >= abs_start || range.end >= abs_start && range.end <= abs_end || range.end >= abs_end && range.start <= abs_start;
                            });
                            filtered_ranges.forEach(function (range, index) {
                                var Loaded_dur = (abs_end - abs_start) * progress;
                                var start2 = Math.max(range.start - abs_start, 0);
                                var end2 = Math.min(range.end - abs_start, Loaded_dur);
                                var exists = Array.from(loaded.children).filter(function (a) {
                                    return a.getAttribute('data-index') === index.toString();
                                });
                                var p;
                                if (exists.length > 0) {
                                    p = exists[0];
                                    p.style.left = ''.concat(start2 / Loaded_dur * 100, '%');
                                    p.style.width = ''.concat((end2 - start2) / Loaded_dur * 100, '%');
                                    p.setAttribute('data-start', start2);
                                    p.setAttribute('data-end', end2);
                                } else {
                                    p = document.createElement('div');
                                    p.classList.add('dplayer-loaded-strips');
                                    p.setAttribute('data-index', index);
                                    p.style.left = ''.concat(start2 / Loaded_dur * 100, '%');
                                    p.style.width = ''.concat((end2 - start2) / Loaded_dur * 100, '%');
                                    p.setAttribute('data-start', start2);
                                    p.setAttribute('data-end', end2);
                                    loaded.appendChild(p);
                                }
                            });
                        });
                    }
                    break;
                case 'nomode':
                    if (type === 'loaded') {
                        this.loaded = LPercentage;
                    }
                    this.elements[type].style.width = percentage * 100 + '%';
                    if (this.RangesChanged && ranges && ranges.length > 0) {
                        type = 'loaded';
                        this.elements[type].style.width = LPercentage * 100 + '%';
                        if (ranges.length < previousRanges.length) {
                            Array.from(this.elements[type].children).forEach(function (a) {
                                return _this.elements[type].removeChild(a);
                            });
                        }
                        ranges.forEach(function (range, index) {
                            var start = range.start;
                            var end = range.end;
                            var exists = Array.from(_this.elements[type].children).filter(function (a) {
                                return a.getAttribute('data-index') === index.toString();
                            });
                            var Loaded_dur = _this.player.video.duration * LPercentage;
                            var p;
                            if (exists.length > 0) {
                                p = exists[0];
                                p.style.left = ''.concat(start / Loaded_dur * 100, '%');
                                p.style.width = ''.concat((end - start) / Loaded_dur * 100, '%');
                                p.setAttribute('data-start', start);
                                p.setAttribute('data-end', end);
                            } else {
                                p = document.createElement('div');
                                p.classList.add('dplayer-loaded-strips');
                                p.setAttribute('data-index', index);
                                p.style.left = ''.concat(start / Loaded_dur * 100, '%');
                                p.style.width = ''.concat((end - start) / Loaded_dur * 100, '%');
                                p.setAttribute('data-start', start);
                                p.setAttribute('data-end', end);
                                _this.elements[type].appendChild(p);
                            }
                        });
                    }
                    break;
                default:
                    console.warn('An internal Error occurred, severity:none');
                    break;
                }
            }
        },
        {
            key: 'setMode',
            value: function setMode(mode) {
                this.mode = mode;
                if (this.loaded) {
                    this.set('loaded', this.loaded);
                }
            }
        }
    ]);
    return Bar;
}();
export default Bar;